import React from "react";

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="flex mx-4 pt-4 pb-2">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`text-center relative p-2 rounded-[20px] ${
            step.title && currentStep === index ? "bg-[#cde3f1] px-4 mx-2" : "text-gray-600"
          }`}
        >
          {step.title && (
            <div
              className={`${
                currentStep === index ? "text-[#0976BC] font-semibold" : "text-gray-600"
              }`}
            >
              {step.title}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
