import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";
import {
  BsCalendar,
  BsFileEarmarkArrowDown,
  BsFilter,
  BsPlusSquare,
} from "react-icons/bs";
import { Link, Outlet } from "react-router-dom";
import InviteCoachDialog from "./InviteCoachDialog";
import { CalendarIcon } from "@heroicons/react/24/outline";
import {
  getUsersByGroupId,
  listAllGroups,
} from "../../../services/user.service";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import debounce from "lodash/debounce";
import { forgetPasswordService } from "../../../services/auth.service";
import SuccessModal from "./SuccessModal";

const ListeDesCoachs = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({});

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupList = await listAllGroups(1, 25);
        const filteredGroups = groupList.filter(
          (group) => group.name.toLowerCase() !== "admins"
        );
        setGroups(filteredGroups);

        const coachGroup = filteredGroups.find(
          (group) => group.name.toLowerCase() === "coachs"
        );
        if (coachGroup) {
          setSelectedGroup(coachGroup);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(parseInt(dateString));
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    if (!selectedGroup) return;

    fetchUsersByGroup();
  }, [selectedGroup, page, rowsPerPage]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      fetchUsersByGroup(term);
    }, 500),
    [selectedGroup, page, rowsPerPage]
  );

  const handleSearchInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => group.name.toLowerCase() !== "admins");
  }, [groups]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (shouldRefresh = false) => {
    setOpen(false);
    if (shouldRefresh) {
      fetchUsersByGroup();
    }
  };

  const handleChangePage = (event, newPage) => {
    setSearchTerm("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(coaches.map((coach) => coach.id));
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGroupSelect = useCallback((groupId) => {
    setSelectedGroup(groupId);
    handleMenuClose();
  }, []);

  const fetchUsersByGroup = async (searchTerm = "") => {
    setLoading(true);
    try {
      const users = await getUsersByGroupId(
        selectedGroup.id,
        page + 1,
        rowsPerPage,
        searchTerm
      );
      console.log("API Response:", users);

      setCoaches(users || []);
      setTotalRecords(users.length || 0);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  
  const relancer = async (e, email, coachId) => {
    e.preventDefault();
    try {
      setLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [coachId]: true,
      }));

      await forgetPasswordService({ email });
      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [coachId]: false, 
      }));
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des utilisateurs</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-4">
        <Button

style={{ textTransform: "none" }}
          className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium"
          startIcon={<BsPlusSquare />}
          onClick={handleClickOpen}
        >
          Inviter un nouvel utilisateur
        </Button>
      </div>
      <InviteCoachDialog
        open={open}
        onClose={(shouldRefresh) => handleClose(shouldRefresh)}
        groups={filteredGroups}
      />

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, Réf ID, Document,..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button  style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md">
              <BsCalendar className="mr-2" />
              Date
            </Button>
            <Button style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md"
              onClick={handleFilterClick}
            >
              <BsFilter className="mr-2" />
              Filtres
            </Button>
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {filteredGroups.map((group) => (
                <MenuItem
                  key={group.id}
                  onClick={() => handleGroupSelect(group)}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Menu>
            <Button style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === coaches.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all coaches" }}
                  />
                </TableCell>
                <TableCell>Nom d'utilisateur</TableCell>
                <TableCell>Date d'invitation</TableCell>
                <TableCell>Rôle</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress style={{ color: "#87CEEB" }} />
                  </TableCell>
                </TableRow>
              ) : coaches.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Aucune donnée à afficher
                  </TableCell>
                </TableRow>
              ) : (
                coaches.map((coach) => {
                  const isItemSelected = isSelected(coach.id);
                  const isLoading = loadingStatus[coach.id] || false; 
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, coach.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={coach.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": coach.id }}
                        />
                      </TableCell>
                      <TableCell>
                        {coach.firstName + " " + coach.lastName}
                      </TableCell>
                      <TableCell className="px-2 py-1">
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>{formatDate(coach?.createdTimestamp)}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="font-semibold">
                          {selectedGroup?.name}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="font-semibold">
                          {coach?.enabled ? "Actif" : "Bloqué"}
                        </span>
                      </TableCell>
                      <TableCell>
                      {!coach?.emailVerified ? (
                        <button
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[6px] rounded-full text-xs"
                          onClick={(e) => relancer(e, coach?.email, coach.id)} 
                          disabled={isLoading} 
                        >
                          {isLoading ? "Envoi..." : "Relancer"} 
                        </button>
                      ) : (
                        "-"
                      )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </div>
      <Outlet />
      <SuccessModal open={showSuccessModal} onClose={handleCloseSuccessModal} />
    </div>
  );
};

export default ListeDesCoachs;
