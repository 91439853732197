import React, { useEffect, useState } from 'react'

const ConventionModalPreview = ({files}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [conventionUrl, setConventionUrl] = useState(null)
    console.log(files, "##########################################")

     

    
    useEffect(()=> {
        const convention = files?.filter((file)=> file?.fileType === "CONVENTION")
        const url = convention[0]?.files[0]?.url
        const finalUrl = ensureHttps(url);
        setConventionUrl(finalUrl)
    },[files, isModalOpen])

    const ensureHttps = (url) => {
      if (!url?.startsWith('https://')) {
        return `https://${url}`;
      }
      return url;
    };
  return (
    <div>
        <div>
            <button className='text-[#05CD99] underline' onClick={()=> setIsModalOpen(true)}>
                Signée
            </button>
        </div>
        {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 h-2/3 md:h-auto overflow-y-auto flex flex-col">
            <div className="flex justify-between items-start mb-4">
              {conventionUrl && <div className="w-full relative">
                <iframe
                  id="pdfIframe"
                  src={`${conventionUrl}#toolbar=0`}
                  title="PDF Document"
                  width="100%"
                  height="300px"
                  className="border border-sky-500 rounded"
                ></iframe>
              </div>}
            </div>

         
            <div className='flex items-center justify-center'>
            <button
              onClick={()=> setIsModalOpen(false)}
              className="text-white bg-[#0976BC] rounded-[23px]  text-center p-2 w-min"
            >
              Fermer
            </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ConventionModalPreview