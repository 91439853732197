
import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import profile from "../../../assets/profile.png";
import { useAuth } from '../../../contexts/AuthContext';
import Avatar from "react-avatar";

const UCPReglages = () => {
  const {userData} = useAuth();
  const [genre, setGenre] = useState("Homme");
  const [pays, setPays] = useState("Tunisie");
  const [langue, setLangue] = useState("Français");
  const [email, setEmail] = useState(userData?.email);
  const [nom, setNom] = useState(userData?.family_name);
  const [prenom, setPrenom] = useState(userData?.given_name);
  const [isEditable, setIsEditable] = useState(false);

  const handleGenreChange = (event) => {
    setGenre(event.target.value);
  };

  const handlePaysChange = (event) => {
    setPays(event.target.value);
  };

  const handleLangueChange = (event) => {
    setLangue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNomChange = (event) => {
    setNom(event.target.value);
  };

  const handlePrenomChange = (event) => {
    setPrenom(event.target.value);
  };

  const handleModifierClick = () => {
    setIsEditable(true);
  };

  const handleEnregistrerClick = () => {
    setIsEditable(false);
  };

  const fieldStyle = {
    height: "56px",
  };

  return (
    <Box className="mx-auto p-6 text-foreground mb-5 bg-slate-50 h-full">
      <Paper
        elevation={3}
        className="border rounded-lg bg-white overflow-hidden shadow-none"
      >
        {/* Banner */}
        <Box className="bg-gradient-to-r from-[#c1e0f1] to-[#f9d9db] w-full h-10"></Box>
        <Box className="">
          <div className="flex items-center justify-between px-6 pt-4 gap-2">
            {/* Bloc image + informations */}
            <div className="flex items-center gap-2">
            <Avatar name={userData?.family_name + " " + userData?.given_name} round={true} size="34" />
              <div className="leading-5">
                <p className="mb-0 font-bold ">
                  {prenom} {nom}
                </p>
                <span className="text-xs">{email}</span>
              </div>
            </div>
            {/* Bouton Modifier */}
            {!isEditable && (
              <Button
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                onClick={handleModifierClick}
                style={{ textTransform: "none" }}
              >
                Modifier
              </Button>
            )}
          </div>
        </Box>

        {/* Formulaire */}
        <Box className="p-6">
          <Grid container spacing={3}>
            {/* Nom */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Nom</label>
                <TextField
                  fullWidth
                  placeholder="Nom"
                  variant="outlined"
                  value={nom}
                  onChange={handleNomChange}
                  disabled={!isEditable}
                  sx={fieldStyle}
                />
              </Box>
            </Grid>

            {/* Prénom */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Prénom</label>
                <TextField
                  fullWidth
                  placeholder="Prénom"
                  variant="outlined"
                  value={prenom}
                  onChange={handlePrenomChange}
                  disabled={!isEditable}
                  sx={fieldStyle}
                />
              </Box>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Email</label>
                <TextField
                  fullWidth
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  disabled={!isEditable}
                  variant="outlined"
                  sx={fieldStyle}
                />
              </Box>
            </Grid>

            {/* Genre */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Genre</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={genre}
                    sx={fieldStyle}
                    onChange={handleGenreChange}
                    displayEmpty
                    disabled={!isEditable}
                  >
                    <MenuItem value="Homme">Homme</MenuItem>
                    <MenuItem value="Femme">Femme</MenuItem>
                    <MenuItem value="Autre">Autre</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Pays */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Pays</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={pays}
                    sx={fieldStyle}
                    onChange={handlePaysChange}
                    displayEmpty
                    disabled={!isEditable}
                  >
                    <MenuItem value="France">France</MenuItem>
                    <MenuItem value="Tunisie">Tunisie</MenuItem>
                    <MenuItem value="Canada">Canada</MenuItem>
                    <MenuItem value="Autre">Autre</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Langue */}
            <Grid item xs={12} sm={6}>
              <Box className="mb-2">
                <label className="block mb-1 text-sm font-medium">Langue</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={langue}
                    sx={fieldStyle}
                    onChange={handleLangueChange}
                    displayEmpty
                    disabled={!isEditable}
                  >
                    <MenuItem value="Français">Français</MenuItem>
                    <MenuItem value="Anglais">Anglais</MenuItem>
                    <MenuItem value="Arabe">Arabe</MenuItem>
                    <MenuItem value="Espagnol">Espagnol</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          {/* Bouton + Ajouter adresse email */}
          {isEditable && (
            <Box className="mt-4">
              <Button
                variant="outlined"
                className="text-[#0976BC] border-[#0976BC] hover:border-[#065A94] hover:bg-[#065A94] hover:text-white transition-colors duration-300 px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                style={{ textTransform: "none" }}
              >
                + Ajouter adresse email
              </Button>
            </Box>
          )}

          {/* Bouton Enregistrer */}
          {isEditable && (
            <Box className="mt-4 justify-end flex gap-2">
              <Button
                variant="contained"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                onClick={handleEnregistrerClick}
                style={{ textTransform: "none" }}
              >
                Enregistrer
              </Button>
              <Button
                variant="outlined"
                className="text-[#0976BC] border-[#0976BC] hover:border-[#065A94] hover:bg-[#065A94] hover:text-white transition-colors duration-300 px-[20px] py-[8px] rounded-lg shadow-none text-sm font-normal"
                style={{ textTransform: "none" }}
              >
                Annuler
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default UCPReglages;
