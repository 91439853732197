import React, { useEffect, useState } from 'react'
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { useParams } from "react-router-dom";
import {getSurveyResponseById} from "../../../../services/surveyResponse.service"
import { CircularProgress } from '@mui/material';

const DetailsFormulaireJuridique = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [surveyModel, setSurveyModel] = useState(null);
    const [surveyModel2, setSurveyModel2] = useState(null);
    const { id } = useParams();
  
    const fetchSurveyResponse = async () => {
      setLoading(true);
      try {
        const data = await getSurveyResponseById(id);
        console.log('data mtaa l foorm fhemtny', data)
        setData(data);
        if(data){
        const formJuridique = data?.surveyResponse?.files?.filter((file)=> file?.fileType === "JURIDIQUE")
        console.log(formJuridique[0]?.fildes, "*************JURIDIQUE******************")
        const parsedJson = JSON.parse(formJuridique[0]?.fildes)
        const survey = new Model(parsedJson)
        const survey2 = new Model(data?.surveyResponse?.program?.form?.fields)
        // survey.data = data.surveyResponse.responses  to be changed
        survey2.data = data.surveyResponse.responses
        survey.mode = "display"
        survey2.mode = "display"
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        survey2.applyTheme(PlainLight);
        survey2.locale = "fr";
        setSurveyModel(survey)
        setSurveyModel2(survey2)
      }
      } catch (error) {
        console.error("Error fetching survey responses:", error);
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      fetchSurveyResponse();
    }, []);


  return (
    <>
        {loading ? <div className="text-center p-4"><CircularProgress /></div> : null}
        <div>
        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
          <h5>Détails de candidature</h5>
            <span className="text-gray-950 ">Statut :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Brouillon"
                    ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                    : data?.surveyResponse?.status === "Rejeté"
                      ? "text-red-800 bg-red-100 border border-red-900"
                      : "text-[#05CD99] bg-green-100 border border-[#05CD99]"
                }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950">Nom et prénom :</span>{" "}
            {data?.userData?.username || "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">
              Titre de l'appel à candidature:
            </span>{" "}
            {data?.surveyResponse?.program?.title || "N/A"}
          </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">
              Description du projet :
            </span>{" "}
            {data?.surveyResponse?.program?.description || "N/A"}
          </div>
          <hr />
          </div>
        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
            <h5>Candidature détails: </h5>
            <div>
            {surveyModel2 && <Survey model={surveyModel2} />} 
            </div>
        </div>
        </div>
        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
            <h5>Justificatif: Documents Juridiques</h5>
            <div>
            {surveyModel && <Survey model={surveyModel} />} 
            </div>
        </div>
    </>
  )
}

export default DetailsFormulaireJuridique
