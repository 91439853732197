import React, { useContext } from "react";
import { StepContext } from "../../../../contexts/StepContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ArrowRightIcon, ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { Button, TextField, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const StepOne = ({ handleNext }) => {
  const { formData, setFormData, images, setImages } = useContext(StepContext);

  const initialValues = formData;
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Le titre est requis')
      .min(3, 'Le titre doit contenir au moins 3 caractères'),
    submissionDate: Yup.date()
      .required('La date limite de soumission est requise')
      .nullable()
      .min(new Date(), 'La date limite de soumission doit être ultérieure à aujourd\'hui'),
    description: Yup.string()
      .required('La description est requise'),
    exigence: Yup.string()
      .required("L'exigence est requise")
      .min(10, "L'exigence doit contenir au moins 10 caractères"),
    status: Yup.string()
      .required('Le statut est requis'),
    category: Yup.string()
      .required('La catégorie est requise'),
    images: Yup.mixed().required('Veuillez sélectionner une image'),
  });



  const onSubmit = (values) => {
    console.log(values, '*****')
    setFormData(values);
    handleNext();
  };

  const handleFileChange = (e, setFieldValue) => {
    const files = Array.from(e.target.files);
    setImages(files);
    setFieldValue('images', files);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isValid }) => (
        <Form className="p-8 bg-white shadow-md rounded-lg mt-2">
          <div className="row">
            {/* Title Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Titre de l'appel à candidature
              </label>
              <Field
                type="text"
                name="title"
                className="w-full p-3 border border-gray-400 rounded-md "
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Submission Date Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Date limite de soumission
              </label>
              <Field
                type="date"
                name="submissionDate"
                className="w-full p-3 border border-gray-400 rounded-md "
              />
              <ErrorMessage
                name="submissionDate"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Status Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Statut
              </label>
              <Field
                as="select"
                name="status"
                className="w-full p-3 border border-gray-400 rounded-md "
              >
                <option value="Publié">publié</option>
                <option value="Brouillon">brouillon</option>
                <option value="Fermé">fermé</option>
              </Field>
              <ErrorMessage
                name="status"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Image Upload Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Image
              </label>
              <input
                type="file"
                multiple
                onChange={(e) => handleFileChange(e, setFieldValue)}
                className="w-full p-3 border border-gray-400 rounded-md "
              />
              <ErrorMessage
                name="images"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* desc Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Description/ Objectif
              </label>
              <Field
                as="textarea"
                rows="5"
                name="description"
                className="w-full p-3 border border-gray-400 rounded-md "
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* exigence Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Exigences
              </label>
              <Field
                as="textarea"
                rows="5"
                name="exigence"
                className="w-full p-3 border border-gray-400 rounded-md "
              />
              <ErrorMessage
                name="exigence"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            {/* Category Input */}
            <div className="col-md-6 mb-3">
              <label className="block text-gray-800 text-base mb-1">
                Catégorie
              </label>
              <Field
                as="select"
                name="category"
                className="w-full p-3 border border-gray-400 rounded-md "
              >
                <option value="technology">Technologie</option>
                <option value="business">Business</option>
                <option value="education">Éducation</option>
                <option value="health">Santé</option>
              </Field>
              <ErrorMessage
                name="category"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </div>

          {/* Next Button */}
          <div className="d-flex justify-content-end mt-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ArrowRightIcon className="w-5 h-5" />}
              disabled={!isValid}
              className=" bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              style={{ textTransform: "none" }}
            >
              Suivant
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
