import React from 'react'
import SurveyCreatorWidget from '../../features/AdminSurveyCreator'

const CreateFormJur = () => {
  return (
    <div className='mx-auto p-6 bg-card text-foreground  mb-5 bg-slate-50'>
        <h3 className='mb-4'>Justificatif :Téléchargement des Documents Juridiques</h3>
        <SurveyCreatorWidget/>
    </div>  
  )
}

export default CreateFormJur