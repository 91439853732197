import axios from "axios";

export const createFormJuridique = async (formData, coachId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/create-juridique-survey/${coachId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error creating form", error);
    throw error;
  }
};

export const getAllFormsJur = async (queryParams) => {
  const {
    // page,
    // limit,
    // status,
    // searchTerm,
    // coachNote,
    startCreatedAt,
    endCreatedAt,
    coachId
  } = queryParams;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/formulaire-juridique/${coachId}`
    );
    console.log(response.data, 'form juridique mta coach12');
    return response?.data;
  } catch (error) {
    console.error("Error fetching all forms:", error);
    throw error;
  }
};


export const assignFormJuridique = async (formJurId, candId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/send-juridique/${formJurId}`, {'surveyResponseIds' : [ candId ] },
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey response ID:', error);
    throw new Error('Failed to fetch survey response ID. Please try again later.');
  }
};