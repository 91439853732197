import React from "react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import BannerImage from "../../assets/BannerAppels.svg";

const ContactBanner = () => {
  return (
    <div className="flex justify-center items-center w-[80%] mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative pt-3">
      <img
        src={BannerImage}
        className="h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute rounded-2xl "
        alt="Banner"
      />
      <div className="relative text-white gap-2 mt-28 xl:mb-48">
        <h2 className="text-center font-roboto-slab text-5xl">
          Contactez-nous
        </h2>
        <div className="flex  items-center mt-4 gap-5">
          <p className="flex items-center gap-2">
            <EnvelopeIcon className="w-6 h-6 text-white" />
            <span className="text-lg">contact@E-entrepreneuriat</span>
          </p>
          <p className="flex items-center gap-2">
            <PhoneIcon className="w-6 h-6 text-white" />
            <span className="text-lg">(011) 6543 8974 210</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
