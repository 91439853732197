import React, { useEffect, useState } from "react";
import { getSurveyResponseById, updateSurveyResponseById } from "../../services/surveyResponse.service";
import { Link, useParams } from "react-router-dom";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import valicon from '../../assets/valicon.png'
import rejicon from '../../assets/rejicon.png'
import { sendConvention } from "../../services/convention.service";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
const EvaluationDetails = () => {
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const [candiRejModal, setCandiRejModal] = useState(false);
  const [candiValModal, setCandiValModal] = useState(false);
  const [coachNote, setCoachNote] = useState(null)
  const { id } = useParams();

  const fetchSurveyResponse = async () => {
    setLoading(true);
    try {
      const data = await getSurveyResponseById(id);
      console.log('data mtaa l foorm fhemtny', data)
      setData(data);
      if(data){
      const parsedJson = JSON.parse(data?.surveyResponse?.program?.form?.fields)
      const survey = new Model(parsedJson)
      survey.data = data.surveyResponse.responses
      survey.mode = "display"
      survey.applyTheme(PlainLight);
      survey.locale = "fr";
      setSurveyModel(survey)
    }
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchSurveyResponse();
  }, []);

  const handleCoachNote = (event) => {
    setCoachNote(event.target.value)
  };

  const rejectionCandidature = async() => {
      await updateSurveyResponseById(id,{
        status: "Rejeté",
        coachNote : coachNote
      })
   setCandiRejModal(true)
}

const validationCandidature = async() => {  
    await updateSurveyResponseById(id,{
        status: "Éligible", 
        coachNote: coachNote
      }).then(async (res)=> {
        setCandiValModal(true)
        await sendConvention(res.surveyResponse.id)
      }).catch((err)=>{
        console.log("error in sending convention",err)
      })
}
   
  return (
    <>
      {loading ? <div className="text-center p-4">Loading...</div> : null}
      {candiRejModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60 ">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">
            <img src={rejicon} alt="" width={100} height={100} />
            <h3>Candidature rejetée</h3>
            <p className="text-center text-[#151269]">
                Le candidat a été notifié que sa candidature est refusée.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={()=>setCandiRejModal(false)}
            >
              <Link to="/coach-dashboard/candidatures" className="text-white no-underline">Retour</Link>
            </button>
          </div>
        </div>
      )}
      {candiValModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60 ">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">
            <img src={valicon} alt="" width={100} height={100} />
            <h3>Candidature Validée</h3>
            <p className="text-center text-[#151269]">
                Le candidat a été notifié que sa candidature est éligible.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={()=>setCandiValModal(true)}
            >
              <Link to="/coach-dashboard/candidatures" className="text-white no-underline">Retour</Link>
            </button>
          </div>
        </div>
      )}
      {data ? (
        <div className="p-[3%] flex flex-col gap-4">
          <div>
            <h3>ID Candidat : {data?.surveyResponse?.keycloakUserId}</h3>
          </div>
          <div className="p-[2%] border border-solid border-[#F0F2F5] rounded-[8px]">
            <div className="p-2 w-max bg-[#E2F1FA]">
              <h4>
                <strong className="text-[#23262D]">Note </strong>{" "}
                <span className="text-[#0976BC]">
                  {data?.surveyResponse?.initialEvaluationNote || "non calculée"}
                </span>
              </h4>
            </div>
          {surveyModel && <Survey model={surveyModel} />}
          <div className="flex flex-col justify-start p-4 gap-2 bg-[#E2F1FA] my-2">
            <label for="myTextarea" className="font-semibold text-[#0976BC] text-[28px] ">Commentaires du coach:</label>
            <textarea id="myTextarea" rows="4" cols="50" placeholder="Notes du coach" className="p-2" value={data?.surveyResponse?.coachNote} onChange={handleCoachNote}></textarea>
          </div>
          <div className="flex gap-2 justify-end">
  {/* Rejeter Button */}
  <button 
    className={`text-[#0976BC] flex gap-2 justify-center items-center border border-[#0976BC96] rounded-[20px] py-2 px-4 ${data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer" ? '' : 'text-[#aec5d4]'}`} 
    onClick={rejectionCandidature} 
    disabled={!(data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer")}>
    <XCircleIcon 
      className={`h-5 w-5 ${data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer" ? 'text-[#EE5D50]' : 'text-[#dfa4a7]'}`} 
    />
    Rejeter
  </button>

  {/* Valider Button */}
  <button 
    className={`text-[#0976BC] flex gap-2 justify-center items-center border border-[#0976BC96] rounded-[20px] py-2 px-4 ${data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer" ? '' : 'text-[#aec5d4]'}`} 
    onClick={validationCandidature} 
    disabled={!(data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer")}>
    <CheckCircleIcon 
      className={`h-5 w-5 ${data?.surveyResponse?.status === "SOUMIS" || data?.surveyResponse?.status === "À Évaluer" ? 'text-[#05CD99]' : 'text-[#99c9af]'}`} 
    />
    Valider
  </button>
</div>

          </div>
        </div>
      ) : null}
    </>
  );
};

export default EvaluationDetails;