import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../../pages/Auth/Login/Login";
import Register from "../../pages/Auth/Sign-Up/register";
import ForgetPassword from "../../pages/Auth/Forget-Password/ForgetPassword";
import AppelsCandidature from "../../pages/AppelsCandidature";
import DetailsAppelCandudature from "../../pages/DetailsAppelCandidature";
import CandidateList from "../../pages/coach/liste-condidature";
import LaureatGetProgramSurvey from "../../features/LaureatGetProgramSurvey";
import DashboardStatistics from "../../pages/Laureat-Dashboard/DashboardStatistics";
import SurveyCreatorWidget from "../../features/AdminSurveyCreator";
import LayoutLandingPage from "../../components/common/LayoutLandingPage";
import About from "../../pages/About";
import Contact from "../../pages/Contact";
import Layout from "../../components/common/Layout";
import ListeDesAppelsACandidatures from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures";
import PrivateRoute from "../../helpers/PrivateRoute";
import ListeDesFormulaires from "../../pages/Unite-De-Gestion-Dashboard/ListeDesFormulaires";
import ListeDesCoachs from "../../pages/Unite-De-Gestion-Dashboard/ListesDesUtilisteurs";
import Reglages from "../../pages/Unite-De-Gestion-Dashboard/Reglages";
import CreateAppelACandidature from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature";
import UDGSurveyCreator from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature/Survey/UDGSurveyCreator";
import { StepProvider } from "../../contexts/StepContext";
import Formulaires from "../../pages/Unite-De-Gestion-Dashboard/ListesDesAppelsACandidatures/CreateAppelACandidature/Formulaires";
import LaureatReglages from "../../pages/Laureat-Dashboard/Reglages";
import LaureatStatistics from "../../pages/Laureat-Dashboard/LaureatStatistics";
import EvaluationDetails from "../../pages/coach/EvaluationDetails";
import DetailsFormulaireJuridique from "../../pages/coach/ListeCandidatures/FormulaireJuridique/DetailsFormulaireJuridique"
import ListeFormJuridique from "../../pages/coach/ListeFormJuridique";
import CreateFormJur from "../../pages/coach/CreateFormJur";
import DetailsAppelACandidatureSoumis from "../../pages/Laureat-Dashboard/DetailsAppelACandidatureSoumis";
import ListeCandidatures from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures";
import AssignCoach from "../../pages/Unite-De-Gestion-Dashboard/ListeCandidatures/AssignCoach";
import UCPDashboard from "../../pages/UCPDashboard";
import UCPReglages from "../../pages/UCPDashboard/reglages";
import ListeDesCandidaturesEligible from "../../pages/UCPDashboard/listeDesCandidaturesEligible";
import GetFormJuridique from "../../pages/Laureat-Dashboard/LaureatStatistics/GetFormJuridique";
import UCPDetails from "../../pages/UCPDashboard/details";
 
const LandingPage = React.lazy(() => import("../../pages/LandingPage"));
 
const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutLandingPage />,
    children: [
      { index: true, element: <LandingPage /> },
      { path: "a-propos", element: <About /> },
      { path: "appels-a-candidature", element: <AppelsCandidature /> },
      {
        path: "appels-a-candidature/details/:id",
        element: <DetailsAppelCandudature />,
      },
      { path: "contact", element: <Contact /> },
    ],
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <Register />,
  },
  {
    path: "/auth/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "laureat-dashboard",
    element: <PrivateRoute />, 
    children: [
      {
        element: <Layout />, 
        children: [
          { index: true, element: <LaureatStatistics /> },
          { path: "details/:id", element: <DetailsAppelACandidatureSoumis /> },
          { path: "fill-form", element: <LaureatGetProgramSurvey /> },
          { path: "reglages", element: <LaureatReglages /> },
          { path: "formJuridique/:surveyResponseId", element: <GetFormJuridique /> }, 
        ],
      },
    ],
  },
  {
    path: "coach-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <DashboardStatistics /> },
         {
            path: "candidatures",
            element: <CandidateList />,
          },
 
          {
            path: "candidatures/:id",
            element: <EvaluationDetails />,
          },
          {
            path: "candidatures/:id/formulaires-juridique",
            element: <ListeFormJuridique />,
          },
          {
            path: "candidatures/:id/details-juridique",
            element: <DetailsFormulaireJuridique />,
          },
          {
            path: "candidatures/:id/formulaires-juridique/creer-form-juridique",
            element: <CreateFormJur />,
          },
 
          { path: "fournisseurs", element: <SurveyCreatorWidget /> },
          { path: "reglages", element: <UCPReglages /> },
        ],
      },
    ],
  },
  {
    path: "unite_de_gestion-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <DashboardStatistics /> },
          {
            path: "Appels-à-candidatures",
            element: <ListeDesAppelsACandidatures />,
            children: [
              {
                path: "create-programme",
                element: (
                  <StepProvider>
                    <CreateAppelACandidature />
                  </StepProvider>
                ),
                children: [
                  { path: "create-form", element: <UDGSurveyCreator /> },
                  { path: "assign-form", element: <Formulaires /> },
                ],
              },
            ],
          },
          {
            path: "formulaires",
            element: <ListeDesFormulaires />,
            children: [
              { path: "create-form", element: <SurveyCreatorWidget /> },
            ],
          },
          { path: "liste-utilisateurs", element: <ListeDesCoachs /> },
          { path: "liste-condidatures", element: <ListeCandidatures /> },
          { path: "assign-coach", element: <AssignCoach /> },
 
          { path: "reglage", element: <UCPReglages /> },
        ],
      },
    ],
  },
  {
    path: "unite_de_coordination_de_projet-dashboard",
    element: <PrivateRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <UCPDashboard /> },
          { path: "candidatures", element: <ListeDesCandidaturesEligible /> },
          { path: "candidatures/details/:id", element: <DetailsFormulaireJuridique /> },
          { path: "reglage", element: <UCPReglages /> },
        ],
      },
    ],
  },
]);
 
export default router;