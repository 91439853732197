import React from 'react'
import proposImg5 from "../../assets/images/Image5-propos.png";
import proposImg6 from "../../assets/images/Image6-propos.png";

const Team = () => {
  return (
    <div className="bg-[#0976BC] ">
      <div className="container my-4 py-4">
        <div className="px-[10%] text-white">
          <h2 className="  text-center font-roboto-slab text-5xl">
            Notre équipe
          </h2>
          <p className="text-center  w-[60%] mx-auto">
            Notre équipe est composée de plusieurs unités : la Coordination
            nationale avec 2 membres, le Pool Fiduciaire et Administrative avec
            15 membres, le Pool Technique avec 14 membres, et l'Unité
            d'exécution provinciale avec 9 membres
          </p>

          <div className="flex flex-wrap justify-center gap-10 mt-10">
            <div className="text-center ">
              <img src={proposImg5} alt="" className="rounded-lg" />
              <h5 className="mt-3 mb-1">Alexis MANGALA NGONGO</h5>
              <p className="text-sm">Coordonnateur national</p>
            </div>
            <div className="text-center ">
              <img src={proposImg6} alt="" className="rounded-lg" />
              <h5 className="mt-3 mb-1">Véronique NGALULA BAKAMONA</h5>
              <p className="text-sm">Assistante du Coordonnateur national</p>
            </div>
          </div>

          <div>
            <h2 className="text-center mt-4  text-2xl ">
              La coordination nationale
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team