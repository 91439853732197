import React from "react";

const DocsSigné = ({ pdfUrl, onClose }) => {
    console.log(pdfUrl)
    const ensureHttps = (url) => {
        if (!url?.startsWith('https://')) {
          return `https://${url}`;
        }
        return url;
      };
     
      const finalUrl = ensureHttps(pdfUrl);
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 h-2/3 md:h-auto overflow-y-auto flex flex-col">
                <div className="flex justify-between items-start mb-4">
                    <div className="w-full relative">
                        <iframe
                            id="pdfIframe"
                            src={`${finalUrl}#toolbar=0`}
                            title="PDF Document"
                            width="100%"
                            height="300px"
                            className="border border-sky-500 rounded"
                        ></iframe>
                    </div>
                </div>

                <div className="flex justify-between items-start mb-4 m-auto">
                    <div className="flex flex-col space-y-2 ml-4 p-2">
                        <div className="flex space-x-4 items-center">
                            <a
                                href={pdfUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sky-600 hover:text-sky-800 font-semibold"
                            >
                                Télécharger le document
                            </a>
                        </div>
                    </div>
                </div>

                <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700 mt-6 text-center"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
};

export default DocsSigné;
