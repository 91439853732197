import React, { useContext } from "react";
import profile from "../../assets/profile.png";
import { ActiveMenuContext } from "../../contexts/MenuContext";
import arrow from "../../assets/arrow-down.svg";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../contexts/AuthContext";
import UserMenu from "./UserMenu";
import { BellIcon } from "@heroicons/react/24/outline";

const Header = () => {
  const { activeMenu, setActiveMenu } = useContext(ActiveMenuContext);
  const { user } = useAuth();
  console.log(activeMenu);

  return (
    <div className="px-2 flex justify-between items-center w-full max-h-max border-b gap-2  h-[89px]  ">
      <div className="flex gap-2 flex-1">
        <button onClick={() => setActiveMenu(!activeMenu)}>
          {activeMenu ? (
            <XMarkIcon className="h-8 w-8 text-gray-500" />
          ) : (
            <Bars3Icon className="h-8 w-8 text-gray-500" />
          )}
        </button>
        <form className="w-[80%] lg:w-[40%] ms-4">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mr-4">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="w-full py-[12px] px-12 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
              placeholder="Rechercher"
              required
            />
          </div>
        </form>
      </div>
      <div className="flex items-center gap-3 mr-8">
        <UserMenu userName={user} img={profile} />
        <BellIcon className="h-6 w-6 text-gray-500" />
      </div>
    </div>
  );
};

export default Header;
