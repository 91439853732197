import React, { useState } from "react";
import { format } from "date-fns";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@tanstack/react-query";
import { getSurveyResponsesForEligibleCandidate } from "../../../services/surveyResponse.service";
import { getAllProgramsFilters } from "../../../services/program.service";
import { getStatusDetails } from "../../../helpers/getStatusColor";
import { Button } from "@mui/material";
import ConventionUploadModal from "./ConventionUploadModal";
import { useNavigate } from "react-router-dom";

const ListeDesCandidaturesEligible = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [surveyResponseId, setSurveyResponseId] = useState(null);
  const [laureat, setLaureat] = useState(null);
  const [program, setProgram] = useState(null);
  const handleOpenModal = (id, user, program) => {
    setProgram(program);
    setLaureat(user);
    setSurveyResponseId(id);
    setIsModalOpen(true);
  };

  const fetchProgramFilters = async () => {
    try {
      const programFilters = await getAllProgramsFilters();
      return programFilters;
    } catch (error) {
      console.log("error in fetching program filters list", error);
    }
  };
  const [selectedProgram, setSelectedProgram] = useState("");
  const filtersQuery = useQuery({
    queryKey: ["programFilters"],
    queryFn: fetchProgramFilters,
  });
  const [filter, setFilter] = useState("");

  const handleFilter = (event) => {
    setFilter(event.target.value);
  };
  const handleChange = (event) => {
    const parsedFilterObject = JSON.parse(event.target.value);
    setSelectedProgram(parsedFilterObject);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((row) => row.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchSurveyResponses = async () => {
    try {
      const data = await getSurveyResponsesForEligibleCandidate({
        page: page + 1,
        limit: rowsPerPage,
        searchTerm: searchQuery,
        programId: selectedProgram.id,
      });
      const totalDocs = data?.totalDocs;
      setTotalRows(totalDocs);
      console.log("my total dzdzedzed", totalDocs);
      return data.data;
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["candidatures", selectedProgram, searchQuery, page, rowsPerPage],
    queryFn: fetchSurveyResponses,
  });

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, 24 - page * rowsPerPage);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleCloseModal = async () => {
    await refetch();
    setIsModalOpen(false);
  };
  console.log(data);
  return (
    <div className="mx-auto p-6 bg-card text-foreground  mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
      <p className="text-gray-500 text-sm  mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="pb-4 flex justify-end">
        <FormControl variant="outlined" sx={{ width: 270 }}>
          <Select
            value={selectedProgram.title}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => (
              <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
            )}
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <i className="bi bi-filter  text-xl mx-2"></i>
                <span style={{ flex: 1, textAlign: "center" }}>
                  {selectedProgram?.title || "Appels à candidature"}
                </span>
              </div>
            )}
            sx={{
              backgroundColor: "#0976BC",
              color: "white",
              borderRadius: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "30px",
                paddingRight: "2.5rem",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {filtersQuery?.data?.map((filter) => (
              <MenuItem
                value={JSON.stringify({ id: filter.id, title: filter.title })}
              >
                {filter.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="border rounded-lg p-3 bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              onChange={handleSearchChange}
              value={searchQuery}
              className="py-[12px] ps-12 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none min-w-80"
              placeholder="Rechercher par date, Réf ID, Document,..."
              required
            />
          </div>

          <div className="flex items-center space-x-4">
            <button
              class="bg-white border  text-secondary-foreground hover:bg-secondary/80 px-4 py-2
           rounded-[4px] flex gap-2 items-center"
            >
              <i class="bi bi-file-earmark-arrow-down"></i>Export
            </button>

            <FormControl variant="outlined" sx={{ width: 150 }}>
              <Select
                value={filter}
                onChange={handleFilter}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                )}
                renderValue={(selected) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <i className="bi bi-filter  text-xl mx-1"></i>
                    <span style={{ flex: 1, textAlign: "center" }}>
                      {selected || <>Filtre</>}
                    </span>
                  </div>
                )}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value="">
                  <>Filter</>
                </MenuItem>
                <MenuItem value="Status">Status</MenuItem>
                <MenuItem value="Appel à candidature -2">Date</MenuItem>
                <MenuItem value="Appel à candidature -3">Filter -3</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < data?.length
                      }
                      checked={
                        data?.length > 0 && selected?.length === data?.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>ID Candidature</TableCell>
                  <TableCell align="center">Nom du candidat</TableCell>
                  <TableCell align="center">Date de la candidature</TableCell>
                  <TableCell align="center">Statut</TableCell>
                  <TableCell align="center">Convention</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((row) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return (
                      <TableRow
                        key={row.id}
                        onClick={(event) =>
                          handleClick(event, row?.id, row?.user, row?.program)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="center">
                          {row?.user?.firstName + " " + row?.user?.lastName}
                        </TableCell>
                        <TableCell align="center">
                          {format(
                            new Date(row.createdAt),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {getStatusDetails(row.status)}
                        </TableCell>
                        <TableCell align="center">
                          {row.conventionStatus == "SIGNÉ" ? (
                            "Signé"
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 capitalize text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                              onClick={() =>
                                handleOpenModal(
                                  row?.id,
                                  row?.user,
                                  row?.program
                                )
                              }
                            >
                              Signer
                            </Button>
                          )}
                        </TableCell>

                        <TableCell>
                          <div className="flex justify-center">
                            <Button
                              variant="contained"
                              color="primary"
                              className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300  text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                              onClick={() => navigate(`details/${row?.id}`)}
                              style={{ textTransform: 'none' }}
                            >
                              Voir détails
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalRows && (
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
      <ConventionUploadModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        surveyResponseId={surveyResponseId}
        laureat={laureat}
        program={program}
      />
    </div>
  );
};

const CandidateEligible = () => <ListeDesCandidaturesEligible />;

export default CandidateEligible;
