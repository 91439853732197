import React, { useContext, useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, Serializer } from "survey-core";
import "survey-core/i18n/french";
import { sendSurveyResponse } from "../services/surveyResponse.service";
import { getProgramToFill } from "../services/program.service";
import { uploadFile } from "../services/uploadFile.service";
import Stepper from "./Stepper";
import "survey-core/defaultV2.min.css";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import LaureatGetSurveyForm from "../pages/Laureat-Dashboard/LaureatGetSurveyForm";


const LaureatGetProgramSurvey = () => {
  Serializer.addProperty("itemvalue", { name: "score:number" });

  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorData, setErrorData] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const programId = localStorage.getItem("programId");
    const token = localStorage.getItem("access-token");
    

    if (!programId || !userId) {
      setError("No program or user selected.");
      setLoading(false);
      return;
    }

    const fetchProgramToFill = async () => {
      try {
        const program = await getProgramToFill(programId, userId);


        const formFields =
          program?.form?.fields || program?.program?.form?.fields;

        if (formFields) {
          const parsedSurvey = JSON.parse(formFields);
          console.log(parsedSurvey, "parsed")

          const stepsTitles = parsedSurvey.pages.map((page) => ({
            title: page.title,
            name: page.name,
          }));

          stepsTitles.push({ title: "", name: "" });

          const survey = new Model(parsedSurvey);
          survey.applyTheme(PlainLight);
          survey.showPreviewBeforeComplete = "showAnsweredQuestions";
          survey.locale = "fr";

          // Fetch old responses if status is Brouillon
          if (program?.surveyResponse?.status === "Brouillon") {
            const oldResponses = program.surveyResponse.responses;
            if (oldResponses) {
              survey.data = oldResponses;
            }
          }
          
          // survey.getAllQuestions().forEach((question) => {
          //   if (question.getType() === "file") {
          //     question.storeDataAsText = false; 
          //   }
          // });

          // Handle file uploads
          survey.onUploadFiles.add(async (sender, options) => {
            try {
              const uploadedFiles = await Promise.all(
                options.files.map(async (file) => {
                  const key = program?.title
                  const uploadResult = await uploadFile(file);
                  return uploadResult.url;
                })
              );
              
              options.callback("success", uploadedFiles);
            } catch (error) {
              options.callback("error");
            }
          });

         

          // survey.showProgressBar = "belowHeader"
          // survey.progressBarShowPageTitles = "true"
          // survey.progressBarShowPageNumbers = "true"

          // Handle step changes
          survey.onCurrentPageChanged.add((sender) => {
            const newIndex = stepsTitles.findIndex(
              (step) => step.name === sender.currentPage.name
            );
            setIsFinalStep(newIndex + 1 === stepsTitles.length);
            setCurrentStep(newIndex);
          });

          survey.onValueChanged.add(() => {
            const allResponsesData = survey.data;
            localStorage.setItem(
              "savedResponses",
              JSON.stringify(allResponsesData)
            );
          });


          // Add enregistrer button for draft saving
          survey.addNavigationItem({
            id: "survey_save_current_page",
            title: "Enregistrer",
            action: async () => {
              const responseData = {
                programId: parseInt(programId),
                responses: survey.data,
                action: "enregistrer",
              };

              try {
                await sendSurveyResponse(token, responseData);
                toast.success(
                  "Vos réponses ont été enregistrées avec succès en tant que brouillon!",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );

                setTimeout(() => {
                  navigate("/laureat-dashboard");
                }, 2000);
              } catch (error) {
                console.error("Error saving the draft:", error);
              }
            },
          });

          // Scoring logic
          function calculateMaxScore(questions) {
            let maxScore = 0;
            questions.forEach((question) => {
              if (question.choices) {
                const maxValue = Math.max.apply(
                  Math,
                  question.choices.map((o) => o.score || 0)
                );
                maxScore += maxValue;
              }
              if (question.rateValues) {
                const maxValue = Math.max.apply(
                  Math,
                  question.rateValues.map((o) => o.score || 0)
                );
                maxScore += maxValue;
              }
              if (question.getType() === "matrix") {
                const maxMatrixValue =
                  Math.max.apply(
                    Math,
                    question.columns.map((o) => o.score || 0)
                  ) * question.rows.length;
                maxScore += maxMatrixValue;
              }
            });
            return maxScore;
          }

          function calculateTotalScore(data) {
            let totalScore = 0;
            data.forEach((item) => {
              const question = survey.getQuestionByValueName(item.name);
              const qValue = item.value;
              if (question?.choices) {
                const selectedChoice = question.choices.find(
                  (choice) => choice.value === qValue
                );
                if (selectedChoice) {
                  totalScore += selectedChoice.score || 0;
                }
              }
              if (question?.rateValues) {
                const selectedRate = question.rateValues.find(
                  (rate) => rate.value === qValue
                );
                if (selectedRate) {
                  totalScore += selectedRate.score || 0;
                }
              }
              if (question.getType() === "matrix") {
                item.data.forEach((dataItem) => {
                  if (!!dataItem.value) {
                    totalScore += dataItem.score || 0;
                  }
                });
              }
            });
            return totalScore;
          }

          // Handling completion
          survey.onCompleting.add((sender) => {
            const maxScore = calculateMaxScore(sender.getAllQuestions());
            const plainData = sender.getPlainData({
              calculations: [{ propertyName: "score" }],
            });
            const totalScore = calculateTotalScore(plainData);
            sender.setValue("maxScore", maxScore);
            sender.setValue("totalScore", totalScore);
          });

          // Handle survey completion
          survey.onComplete.add(async (sender) => {
            const totalScore = sender.getValue("totalScore");
            const maxScore = sender.getValue("maxScore");
            const responseData = {
              programId: parseInt(programId),
              responses: sender.data,
              initialEvaluationNote:(totalScore === undefined || maxScore === undefined) 
              ? "non calculée" 
              : `${totalScore}/${maxScore}`,
              action: "envoyer",
            };

            try {
              await sendSurveyResponse(token, responseData);
              toast.success("Vos réponses ont été envoyées avec succès!", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
              });

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            } catch (error) {
              console.error("Error sending final response:", error);
            }
          });

          setSteps(stepsTitles);
          setSurveyModel(survey);
        } else {
          setErrorData("No survey data found for this program.");
        }
      } catch (err) {
        setError(
          "Failed to fetch the program to fill. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProgramToFill();
  }, [userId, navigate]);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return (
      <div>
        <LaureatGetSurveyForm />
      </div>
    );
  }

  return (
    <div className="App">
      {steps.length > 0 && (
        <Stepper
          steps={steps}
          currentStep={isFinalStep ? steps.length : currentStep}
        />
      )}
      {surveyModel ? (
        // isFinalStep ? (
        //   <div className="flex justify-center flex-col items-center">
        //     <h2 className="text-xl font-bold mb-4">Final Validation</h2>
        //     <button
        //       className="bg-[#0976BC] text-white rounded-[37px] py-3 px-9"
        //       onClick={() => surveyModel.completeLastPage()}
        //     >
        //       Submit
        //     </button>
        //   </div>
        // ) : (
          <Survey model={surveyModel} />
        // )
      )
       : (
        <div>No survey available.</div>
      )}
      <ToastContainer />
    </div>
  );
};

export default LaureatGetProgramSurvey;
