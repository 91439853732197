// import React, { useState } from "react";
// import { PDFDocument, rgb } from "pdf-lib";
// import { uploadFile } from "../../services/uploadFile.service";
// import { useAuth } from "../../contexts/AuthContext";

// const SignDocs = () => {
//   const originalPdfUrl = "https://minio-api.dev.addinn-group.com/public-copa-transforme/convention/1725447883698-e0ri38j4cs.pdf";

//   const [pdfUrl, setPdfUrl] = useState(originalPdfUrl);
//   const [signedPdfUrl, setSignedPdfUrl] = useState(null);
//   const { user } = useAuth();
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isTermsChecked, setIsTermsChecked] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsSubmitting(true);

//     try {
//       const response = await fetch(pdfUrl);
//       const pdfBytes = await response.arrayBuffer();

//       const pdfDoc = await PDFDocument.load(pdfBytes);
//       const pages = pdfDoc.getPages();
//       const lastPage = pages[pages.length - 1];

//       const signatureY = 150;
//       const signatureX = 150;


//       const elementsY = signatureY - 60;

//       lastPage.drawRectangle({
//         x: signatureX,
//         y: elementsY,
//         width: 20,
//         height: 20,
//         borderColor: rgb(0, 0, 0),
//         borderWidth: 1,
//       });

//       lastPage.drawText("X", {
//         x: signatureX + 6,
//         y: elementsY + 5,
//         size: 12,
//         color: rgb(0, 0, 0),
//       });

//       lastPage.drawText(`Signé par: ${user}`, {
//         x: signatureX + 30,
//         y: elementsY + 5,
//         size: 12,
//         color: rgb(0, 0, 0),
//       });

//       lastPage.drawText(`Date: ${new Date().toLocaleDateString()}`, {
//         x: signatureX + 30,
//         y: elementsY - 15,
//         size: 12,
//         color: rgb(0, 0, 0),
//       });

//       const modifiedPdfBytes = await pdfDoc.save();
//       const modifiedPdfFile = new File(
//         [modifiedPdfBytes],
//         `signed_document.pdf`,
//         { type: "application/pdf" }
//       );

//       const uploadedSignedPdf = await uploadFile(modifiedPdfFile, "user");

//       setSignedPdfUrl(uploadedSignedPdf[0].url);
//       setPdfUrl(uploadedSignedPdf[0].url);

//     } catch (error) {
//       console.error("Error uploading data:", error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const handleCloseModal = () => {
//     setPdfUrl(originalPdfUrl);
//     setSignedPdfUrl(null);
//     setIsTermsChecked(false);
//     setIsModalOpen(false);
//   };

//   const enterFullScreen = () => {
//     const iframe = document.getElementById("pdfIframe");
//     if (iframe.requestFullscreen) {
//       iframe.requestFullscreen();
//     } else if (iframe.mozRequestFullScreen) {
//       iframe.mozRequestFullScreen();
//     } else if (iframe.webkitRequestFullscreen) {
//       iframe.webkitRequestFullscreen();
//     } else if (iframe.msRequestFullscreen) {
//       iframe.msRequestFullscreen();
//     }
//   };

//   return (
//     <div className="App">
//       <button
//         onClick={() => setIsModalOpen(true)}
//         className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[5px] rounded-full text-xs min-w-[90px]"
//       >
//         S'adhérer
//       </button>

//       {isModalOpen && (
//         <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
//           <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 h-2/3 md:h-auto overflow-y-auto flex flex-col">
//             <form onSubmit={handleSubmit} className="flex-grow">
//               <div className="flex justify-between items-start mb-4">
//                 <div className="w-full relative">
//                   <iframe
//                     id="pdfIframe"
//                     src={`${pdfUrl}#toolbar=0`}
//                     title="PDF Document"
//                     width="100%"
//                     height="300px"
//                     className="border border-sky-500 rounded"
//                   ></iframe>
//                   <button
//                     type="button"
//                     onClick={enterFullScreen}
//                     className="absolute top-0 right-0 mt-2 mr-2 bg-sky-500 text-white py-1 px-2 rounded"
//                   >
//                     plein ecran
//                   </button>
//                 </div>
//               </div>
//               <div className="flex justify-between items-start mb-4">
//                 <div className="flex flex-col space-y-2 ml-4 p-2">
//                   <h3 className="text-lg font-bold text-gray-700 mb-2">
//                     Documents:
//                   </h3>
//                   <div className="flex space-x-4 items-center">
//                     <a
//                       href={pdfUrl}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-sky-600 hover:text-sky-800 font-semibold"
//                     >
//                       Document original
//                     </a>
//                     {signedPdfUrl && (
//                       <a
//                         href={signedPdfUrl}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         className="text-sky-600 hover:text-sky-800 font-semibold"
//                       >
//                         Document signé
//                       </a>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <div className="flex items-center justify-center mb-4">
//                 <input
//                   type="checkbox"
//                   id="termsCheckbox"
//                   checked={isTermsChecked}
//                   onChange={(e) => setIsTermsChecked(e.target.checked)}
//                   className="mr-2 w-5 h-5"
//                 />
//                 <label
//                   htmlFor="termsCheckbox"
//                   className="text-gray-700 text-lg"
//                 >
//                   J'accepte les termes et conditions.
//                 </label>
//               </div>
//               <div className="flex justify-center">
//                 <button
//                   type="submit"
//                   disabled={isSubmitting || !isTermsChecked}
//                   className={`py-2 px-6 rounded ${isTermsChecked
//                       ? "bg-sky-500 text-white"
//                       : "bg-gray-400 text-gray-200 cursor-not-allowed"
//                     }`}
//                 >
//                   {isSubmitting ? "Soumission en cours..." : "Soumettre"}
//                 </button>
//               </div>
//             </form>
//             <button
//               onClick={handleCloseModal}
//               className="text-gray-500 hover:text-gray-700 mt-6 text-center"
//             >
//               Annuler
//             </button>
//           </div>
//         </div>
//       )}
//     </div>


//   );
// };

// export default SignDocs;



import React from "react";

const SignDocs = ({ onClose }) => {
  const originalPdfUrl =
    "https://minio-api.dev.addinn-group.com/public-copa-transforme/convention/1725447883698-e0ri38j4cs.pdf";

  const handleDownload = () => {
    window.open(originalPdfUrl, "_blank");
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 h-2/3 md:h-auto overflow-y-auto flex flex-col">
        <div className="flex justify-between items-start mb-4">
          <div className="w-full relative">
            <iframe
              id="pdfIframe"
              src={`${originalPdfUrl}#toolbar=0`}
              title="PDF Document"
              width="100%"
              height="300px"
              className="border border-sky-500 rounded"
            ></iframe>
          </div>
        </div>

        <div className="flex justify-between items-start mb-4 m-auto">
          <div className="flex flex-col space-y-2 ml-4 p-2">
            <div className="flex space-x-4 items-center">
              <a
                href={originalPdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sky-600 hover:text-sky-800 font-semibold"
              >
                Télécharger le document
              </a>
            </div>
          </div>
        </div>

        <button
          onClick={onClose} // This will now close the modal
          className="text-gray-500 hover:text-gray-700 mt-6 text-center"
        >
          Fermer
        </button>
      </div>
    </div>
  );
};

export default SignDocs;
