import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/french";
import { StepContext } from "../../../../../contexts/StepContext";
import { StylesManager } from "survey-core";
import "survey-core/survey.i18n";
import { Serializer } from "survey-core";


// Import the theme you want to use
import { LayeredDarkPanelless, BorderlessLight, PlainLight } from "survey-core/themes";

const UDGSurveyCreator = () => {
    Serializer.addProperty("itemvalue", {
        name: "score:number",
        // default: 0,
        // title: "Score",
        // category: "general",
        // visible: true
      });
    
    const { setCurrentStep, setFormData } = useContext(StepContext);
    const navigate = useNavigate();

    const optionsDuCréateur = {
        showLogicTab: false,
        showTranslationTab: false,
        showJSONEditorTab: false,
        isAutoSave: true,
    };

    const créateur = new SurveyCreator(optionsDuCréateur);
    créateur.locale = "fr";

    
    useEffect(() => {
        StylesManager.applyTheme(BorderlessLight); 
    }, []);

    const handleNext = () => {
        const surveyData = créateur.text;
        console.log(surveyData)
        window.localStorage.setItem("json-sondage", surveyData);
        setFormData(prevData => ({
            ...prevData,
            surveyData, 
        }));
        setCurrentStep(3);
        navigate("../");
    };

    return (
        <div>
            <SurveyCreatorComponent creator={créateur} />
            <div className="flex space-x-4 mt-4 flex-row-reverse gap-8 ">
                <button
                    className="bg-[#0976BC] text-white rounded-[37px] py-3 px-9"
                    onClick={handleNext}
                >
                    Suivant
                </button>
                <button
                    className="bg-gray-500 text-white rounded-[37px] py-3 px-9"
                    onClick={() => navigate(-1)}
                >
                    Annuler
                </button>
            </div>
        </div>
    );
};

export default UDGSurveyCreator;
