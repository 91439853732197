import processus from "../../assets/precessus-icon.png";
import evaluation from "../../assets/avalution-icon.png";
import imag from "../../assets/USA.png";

const Value = () => {
  const WorkflowContent = [
    {
      id: 1,
      title: "Processus de candidature",
      description:
        "Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices, orci vitae convallis mattis.",
      icon: processus,
    },
    {
      id: 2,
      title: "Évaluation par des experts",
      description:
        "Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices, orci vitae convallis mattis.",
      icon: evaluation,
    },
    {
      id: 3,
      title: "Opportunités de formation complètes",
      description:
        "Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices, orci vitae convallis mattis.",
      icon: imag,
    },
  ];
  return (
    <div>
      <h2 className="text-center mt-5 mb-4 text-3xl font-roboto-slab px-[20%]">
        Nos valeurs
      </h2>
      <h3 className="text-sm text-center font-normal  text-gray-700 mb-6 px-[20%]">
        Le Projet TRANSFORME est régi par des valeurs contenues dans le Code
        d'éthique et de bonne conduite de son personnel. Ce code recommande le
        service au profit des bénéficiaires cibles et l'honnêteté dans les
        relations. Les principales valeurs attendues d'un agent du Projet COPA
        TRANSFORME RDC sont :
      </h3>
      <div className="flex  flex-col sm:flex-row justify-between   gap-4 lg:gap-12 ">
        {WorkflowContent?.map((card) => (
          <ProcessCard
            key={card?.id}
            title={card?.title}
            description={card?.description}
            id={card?.id}
            icon={card?.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Value;

const ProcessCard = ({ title, id, description, icon }) => {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#F9F9F9] rounded-md px-2 py-4 max-w-[409px] border-[0.75px] border-[#e1e0e0]"
      key={id}
    >
      <img src={icon} width={65} height={65} alt="" className="" key={id} />
      <h1 className="font-semibold text-[#2F2F2F] text-base text-center lg:text-start my-2">
        {title}
      </h1>
      <p className="text-[#808080] max-w-[80%] sm:max-w-[100%] lg:max-w-[80%] text-center text-sm">
        {description}
      </p>
    </div>
  );
};
