import React, { useState, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import dayjs from "dayjs";
import { PDFDocument } from "pdf-lib";

import { uploadFile } from "../../../services/uploadFile.service";
import VisulaizePdf from "./visulaizePdf";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { submitConventionSignature } from "../../../services/convention.service";

const ConventionUploadModal = ({
  open,
  handleClose,
  surveyResponseId,
  laureat,
  program,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateNaissance, setDateNaissance] = useState(dayjs());
  const sigCanvas = useRef(null);
  console.log(pdfUrl);
  const [formData, setFormData] = useState({
    villeNaissance: "",
    carteIdentite: "",
    montantSubvention: "",
  });

  const [errors, setErrors] = useState({
    villeNaissance: false,
    carteIdentite: false,
    montantSubvention: false,
  });

  const user = {
    nom: laureat?.firstName || "",
    prenom: laureat?.lastName || "",
  };

  const steps = [
    "Vérification des informations",
    "Visualiser la convention",
    "Signer la convention",
    "Confirmer et soumettre",
  ];

  const generatePdfWithUserData = async (signatureImageUrl = null) => {
    setIsSubmitting(true);
    const originalPdfUrl =
      "https://minio-api.dev.addinn-group.com/public-copa-transforme/convention/1725447883698-e0ri38j4cs.pdf";

    try {
      const response = await fetch(originalPdfUrl);
      const pdfBytes = await response.arrayBuffer();

      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pages = pdfDoc.getPages();
      const lastPage = pages[pages.length - 1];

      const signatureY = 150;
      const signatureX = 50;
      const elementsY = signatureY - 60;

      lastPage.drawText(`Nom: ${user.nom}`, {
        x: signatureX,
        y: elementsY + 50,
        size: 12,
      });
      lastPage.drawText(`Prénom: ${user.prenom}`, {
        x: signatureX,
        y: elementsY + 30,
        size: 12,
      });
      lastPage.drawText(
        `Date de naissance: ${dateNaissance.format("DD/MM/YYYY")}`,
        { x: signatureX, y: elementsY + 10, size: 12 }
      );
      lastPage.drawText(`Ville de naissance: ${formData.villeNaissance}`, {
        x: signatureX,
        y: elementsY - 10,
        size: 12,
      });
      lastPage.drawText(
        `Numéro de Carte d'identité: ${formData.carteIdentite}`,
        { x: signatureX, y: elementsY - 30, size: 12 }
      );
      lastPage.drawText(
        `Montant du subvention: ${formData.montantSubvention}`,
        { x: signatureX, y: elementsY - 50, size: 12 }
      );

      if (signatureImageUrl) {
        const signatureImageBytes = await fetch(signatureImageUrl).then((res) =>
          res.arrayBuffer()
        );
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
        const signatureDims = signatureImage.scale(0.5);

        lastPage.drawImage(signatureImage, {
          x: lastPage.getWidth() - signatureDims.width - 50,
          y: 50,
          width: signatureDims.width,
          height: signatureDims.height,
        });
      }

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfFile = new File(
        [modifiedPdfBytes],
        `signed_document.pdf`,
        {
          type: "application/pdf",
        }
      );

      const uploadedSignedPdf = await uploadFile(
        modifiedPdfFile,
        "convention",
        "public"
      );
      console.log("uploadedSignedPdf", uploadedSignedPdf);
      setPdfUrl(uploadedSignedPdf[0].url);
    } catch (error) {
      console.error("Error generating and uploading PDF:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = async () => {
    let formErrors = {
      villeNaissance: !formData.villeNaissance,
      carteIdentite: !formData.carteIdentite,
      montantSubvention: !formData.montantSubvention,
    };

    setErrors(formErrors);

    if (
      formErrors.villeNaissance ||
      formErrors.carteIdentite ||
      formErrors.montantSubvention
    ) {
      return;
    }

    if (activeStep === 0) {
      await generatePdfWithUserData();
    } else if (activeStep === 2) {
      const signatureImage = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      await generatePdfWithUserData(signatureImage);
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await submitConventionSignature(surveyResponseId, [{ url: pdfUrl }]);

    toast.success("Convention signée avec succès!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      onClose: () => handleClose(),
    });
    setIsSubmitting(false);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  if (!open) return null;
  const getDynamicTitle = (
    firstName = "N/A",
    lastName = "N/A",
    programName = "N/A"
  ) => {
    return `Signature du document par ${firstName} ${lastName} dans le cadre d'appel à candidautre: ${programName}.`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg px-6 py-8 w-full max-w-4xl relative">
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold text-center mx-10 mb-6 text-[#424243]">
          {getDynamicTitle(
            laureat?.firstName,
            laureat?.lastName,
            program?.title
          )}
        </h2>
        {/* <hr /> */}
        <Stepper
          activeStep={activeStep}
          className="my-3 border border-gray-500 rounded-md py-3 bg-slate-50"
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* <hr /> */}
        {activeStep === 0 ? (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Nom"
                  value={user.nom}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Prénom"
                  value={user.prenom}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date de naissance"
                  type="datetime-local"
                  value={dateNaissance.format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) => setDateNaissance(dayjs(e.target.value))}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Ville de naissance"
                  name="villeNaissance"
                  value={formData.villeNaissance}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.villeNaissance}
                  helperText={
                    errors.villeNaissance
                      ? "Veuillez remplir la ville de naissance"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Numéro de Carte d'identité"
                  name="carteIdentite"
                  value={formData.carteIdentite}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.carteIdentite}
                  helperText={
                    errors.carteIdentite
                      ? "Veuillez remplir le numéro de carte d'identité"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Montant du subvention"
                  name="montantSubvention"
                  value={formData.montantSubvention}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.montantSubvention}
                  helperText={
                    errors.montantSubvention
                      ? "Veuillez remplir le montant du subvention"
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              >
                Suivant
              </Button>
            </div>
          </div>
        ) : activeStep === 1 ? (
          <div>
            {pdfUrl ? (
              <VisulaizePdf pdfUrl={pdfUrl} />
            ) : (
              <p>Chargement du PDF...</p>
            )}
            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                }}
                onClick={handleNext}
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              >
                Suivant
              </Button>
            </div>
          </div>
        ) : activeStep === 2 ? (
          <div className="text-center">
            <div className="flex justify-center">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "border border-black",
                }}
              />
            </div>
            <div className="mt-4">
              <Button
                onClick={clearSignature}
                variant="contained"
                color="secondary"
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              >
                Effacer
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2 ml-2"
              >
                Sauvegarder la signature et continuer
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {pdfUrl ? (
              <VisulaizePdf pdfUrl={pdfUrl} />
            ) : (
              <p>Chargement du PDF...</p>
            )}
            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              >
                Confirmer et Soumettre
              </Button>
            </div>
          </div>
        )}

        {activeStep > 0 && (
          <div className="mt-6 text-center">
            <Button
              variant="text"
              color="secondary"
              onClick={handleBack}
              style={{ textTransform: "none" }}
              className="bg-white hover:bg-[#065A94] transition-colors duration-300 hover:text-white shadow-none rounded-full px-4 py-2 border border-[#0976BC] text-[#0976BC]"
            >
              Retour
            </Button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ConventionUploadModal;
