import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
} from "@mui/material";
import { MagnifyingGlassIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { BsCalendar, BsFileEarmarkArrowDown, BsFilter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/24/outline";
import debounce from "lodash.debounce";
import { getAllSurveyResponses } from "../../../services/surveyResponse.service";
import CircularProgress from "@mui/material/CircularProgress";

const ListeCandidatures = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const fetchCandidatures = useCallback(async (page, limit, searchTerm) => {
    setLoading(true);
    try {
      const response = await getAllSurveyResponses({
        page: page + 1,
        limit,
        searchTerm,
      });
      setCandidatures(response.data);
      setTotalCount(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCandidatures(page, rowsPerPage, searchTerm);
  }, [fetchCandidatures, page, rowsPerPage, searchTerm]);

  const handleAssignCoachClick = () => {
    if (selected.length > 0) {
      const queryString = selected.map((id) => `ids=${id}`).join("&");
      navigate(`/unite_de_gestion-dashboard/assign-coach?${queryString}`);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(candidatures.map((candidature) => candidature.id));
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id, candidature) => {
    if (candidature?.keycloakCoachId !== null) {
      return;
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et assignez les coachs aux utilisateurs.
      </p>
      <div className="flex justify-end mb-4">
        <Button  style={{ textTransform: "none" }}
          className={`transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium ${
            selected.length > 0 ? "bg-[#0976BC]" : "bg-[#CACACA]"
          }`}
          startIcon={<UserPlusIcon className="h-5 w-5" />}
          disabled={selected.length === 0}
          onClick={handleAssignCoachClick}
        >
          Assigner un coach
        </Button>
      </div>
      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, date, rôle,..."
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button  style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsCalendar className="mr-2" />
              Date
            </Button>
            <Button  style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsFilter className="mr-2" />
              Filtres
            </Button>
            <Button   style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === candidatures.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all candidatures" }}
                  />
                </TableCell>
                <TableCell>Nom du candidat</TableCell>
                <TableCell>Date de soumission</TableCell>
                <TableCell>Appel à candidature</TableCell>
                <TableCell>Coach assigné</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />{" "}
                  </TableCell>
                </TableRow>
              ) : candidatures.length > 0 ? (
                candidatures.map((candidature) => {
                  const isItemSelected = isSelected(candidature?.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, candidature?.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={candidature.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!!candidature?.keycloakCoachId}
                          checked={
                            isItemSelected && !candidature?.keycloakCoachId
                          }
                          onChange={(event) =>
                            handleClick(event, candidature?.id, candidature)
                          }
                          inputProps={{ "aria-labelledby": candidature?.id }}
                        />
                      </TableCell>
                      <TableCell>
                        {candidature.user?.firstName}{" "}
                        {candidature.user?.lastName}
                      </TableCell>
                      <TableCell className="px-2 py-1">
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>{formatDate(candidature?.createdAt)}</span>
                        </div>
                      </TableCell>
                      <TableCell>{candidature.program?.title}</TableCell>
                      <TableCell>
                        {candidature?.keycloakCoachId ? (
                          <span className="text-[#05CD99]">Assigné</span>
                        ) : (
                          <span className="text-[#EB2630]">Non assigné</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Aucune donnée affichée
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </div>
    </div>
  );
};

export default ListeCandidatures;
