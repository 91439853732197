import {ClockIcon,  MapPinIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import programimg from "../../assets/program img.png";

const ProgramCard = ({ category, title, description, id, img, ddl }) => {

  const imageUrl = img?.startsWith("http") ? img : `https://${img}`;

  return (
    <div className="bg-[#FFF] rounded-[15px] p-3 border-[0.75px] border-[#e8e8e8] flex flex-col lg:flex-row justify-center items-start gap-8 mx-auto shadow-md w-full max-w-5xl mb-4">
      <div className="w-full lg:w-[30%] rounded-[20px] flex justify-center items-start">
        <img
          alt="Program"
          src={imageUrl}
          className="rounded-[20px] w-full lg:w-auto"
        />
      </div>
      <div className="w-full lg:w-[70%]">
        <p className="text-[#EB2630] text-sm px-[20px] py-[5px] mb-1 bg-[#FCDEE0] w-min rounded-full capitalize">
          {category}
        </p>
        <h3 className="text-xl lg:text-2xl font-semibold my-3">{title}</h3>
        <p className="text-sm lg:text-base text-gray-700 line-clamp-4">
          {description}
        </p>
        <hr className="my-3" />
        <div className="flex flex-wrap items-center text-sm gap-4">
          <p className="flex items-center gap-[10px] mb-0">
            <ClockIcon className="w-5 h-5 text-[#0976BC]" />
            <span className="text-xs lg:text-sm">{ddl}</span>
          </p>
          <p className="flex items-center gap-[10px] mb-0">
            <MapPinIcon className="w-5 h-5 text-[#0976BC]" />
            <span className="text-xs lg:text-sm">
              République Démocratique de Congo
            </span>
          </p>
        </div>
        <div className="flex justify-end items-end mt-2 lg:mt-0">
          <button className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300">
            <Link
              to={`/appels-a-candidature/details/${id}`}
              className="no-underline text-white"
            >
              Découvrir
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};


export default ProgramCard;
