import { CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BsCalendar,
  BsFileEarmarkArrowDown,
  BsFilter,
  BsPlusSquare
} from "react-icons/bs";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getAllForms } from "../../../services/form.service";
const ListeDesFormulaires = () => {
  const location = useLocation();
  const isCreatingForm = location.pathname.includes("create-form");

  const [forms, setForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const getAllformms = async () => {
      try {
        const data = await getAllForms();
        setForms(data);
      } catch (error) {
        console.error("Error fetching forms:", error);
      }
    };
    getAllformms();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCheckboxChange = (formId) => {
    setSelectedForms((prevSelected) =>
      prevSelected.includes(formId)
        ? prevSelected.filter((id) => id !== formId)
        : [...prevSelected, formId]
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isCreatingForm) {
    return <Outlet />;
  }
  console.log("dzadza", forms);
  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des formulaires</h1>
      <p class="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-4">
        <Link to="create-form">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer un nouveau formulaire
          </Button>
        </Link>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, date, rôle,..."
              required
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50"
            >
              <BsCalendar className="mr-2" />
              Date
            </Button>
            <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50"
            >
              <BsFilter className="mr-2" />
              Filtres
            </Button>
            <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm"
            >
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedForms.length > 0 &&
                      selectedForms.length < forms.length
                    }
                    checked={
                      forms.length > 0 && selectedForms.length === forms.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedForms(forms.map((form) => form.id));
                      } else {
                        setSelectedForms([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Titre formulaire</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Dernière modification</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((form) => (
                  <TableRow
                    key={form.id}
                    hover
                    selected={selectedForms.includes(form.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedForms.includes(form.id)}
                        onChange={() => handleCheckboxChange(form.id)}
                      />
                    </TableCell>
                    <TableCell>
                      {form?.fields
                        ? (() => {
                            try {
                              const parsedFields = JSON.parse(form.fields);
                              return parsedFields.title
                                ? parsedFields.title
                                : "Aucun titre trouvé";
                            } catch (error) {
                              return "Aucun titre trouvé";
                            }
                          })()
                        : "Aucun titre trouvé"}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5  text-gray-600" />
                        </span>
                        {formatDate(form.createdAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(form.updatedAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/formulaires/${form.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[6px] rounded-full text-xs"
                      >
                        Voir détails
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={forms.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Lignes par page"
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableContainer>

        <Outlet />
      </div>
    </div>
  );
};

export default ListeDesFormulaires;
