import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/french";
import { Serializer } from "survey-core";
import { createFormJuridique } from "../services/form-juridique.service";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../contexts/AuthContext";
import { PlainLight } from "survey-core/themes";
const SurveyCreatorWidget = () => {
  const navigate = useNavigate();
  const {userId} = useContext(AuthContext)

  Serializer.addProperty("itemvalue", {
    name: "score:number",
  });

  const optionsDuCréateur = {
    showLogicTab: false,
    showTranslationTab: false,
    showJSONEditorTab: false,
    isAutoSave: true,
  };

  const jsonParDéfaut = {
    logoPosition: "right",
  };

  const créateur = new SurveyCreator(optionsDuCréateur);
  créateur.text = JSON.stringify(jsonParDéfaut);



  créateur.locale = "fr";

  const createJurForm = async () => {
    try {
      const jsonSondage = créateur.JSON;
      console.log('my form juridique ', jsonSondage)
      const donnéesFormulaire = {
        fildes: JSON.stringify(jsonSondage),
      };

      const réponse = await createFormJuridique(donnéesFormulaire, userId);
      if(réponse){
        toast.success('Formulaire créer avec succès', {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
      });
      setTimeout(() => {
        navigate(-1);
    }, 2000); 
      }
      console.log("Formulaire créé avec succès:", réponse);
    } catch (error) {
      console.error("Échec de la sauvegarde du sondage:", error);
    }
  };

  créateur.saveSurveyFunc = (saveNo, callback) => {
    window.localStorage.setItem("json-sondage", créateur.text);
    callback(saveNo, true);
  };

  return (
    <div>
      <SurveyCreatorComponent creator={créateur} />
      <div className="flex space-x-4 mt-4 flex-row-reverse gap-8 ">
        <button
          className="bg-[#0976BC] text-white rounded-[8px] py-3 px-9"
          onClick={createJurForm}
        >
          Sauvegarder
        </button>
        <button
          className="bg-gray-500 text-white rounded-[8px] py-3 px-9"
          onClick={() => navigate(-1)}
        >
          Annuler
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default SurveyCreatorWidget;
