import React, { useState, useEffect } from "react";
import { BsCalendar, BsFileEarmarkArrowDown, BsFilter, BsPlusSquare } from "react-icons/bs";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getAllPrograms } from "../../../services/program.service";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  IconButton,
} from "@mui/material";
import { CalendarIcon, ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const ListeDesAppelsACandidatures = () => {
  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [filter, setFilter] = useState(""); 
  const location = useLocation();

  const isCreatingProgramme = location.pathname.includes("create-programme");

  useEffect(() => {
    const getActiveProgram = async () => {
      try {
        const data = await getAllPrograms();
        setPrograms(data);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };
    getActiveProgram();
  }, []);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedPrograms(programs.map((program) => program.id));
    } else {
      setSelectedPrograms([]);
    }
  };

  const handleSelectProgram = (id) => {
    if (selectedPrograms.includes(id)) {
      setSelectedPrograms(
        selectedPrograms.filter((programId) => programId !== id)
      );
    } else {
      setSelectedPrograms([...selectedPrograms, id]);
    }
  };

  const isSelected = (id) => selectedPrograms.includes(id);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "active":
        return "✅";
      case "inactive":
        return "❌";
      case "completed":
        return "✔️";
      case "pending":
        return "🟡";
      default:
        return "";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isCreatingProgramme) {
    return <Outlet />;
  }

  return (
    <div className="mx-auto p-6 text-foreground mb-5 bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">
        Liste des appels à candidatures
      </h2>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-4">
        {/* <Link to="create-programme">
          <Button
            variant="contained"
            color="primary"
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center lowercase font-medium"
            style={{ textTransform: "none" }}
            startIcon={<i className="bi bi-file-earmark-plus my-0"></i>}
          >
            Créer un nouvel appel à candidature
          </Button>
        </Link> */}

        <Link to="create-programme">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center font-medium"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer un nouvel appel à candidature
          </Button>
        </Link>
      </div>
      <Paper className="border rounded-lg p-3 bg-white mt-0">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <InputBase
              placeholder="Rechercher par date, Réf ID, Document,..."
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full"
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button  style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsCalendar className="mr-2" />
              Date
            </Button>
            <FormControl className="relative">
              <div className="flex items-center relative">
                <BsFilter className="absolute left-3 text-gray-500" />

                <Select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="pl-10 pr-5 py-[0px] text-base text-gray-900  rounded-md focus:outline-none focus:ring-0 "
                  displayEmpty
                  IconComponent={() => (
                    <ChevronDownIcon className="h-6 w-6 text-gray-500 ml-2" />
                  )}
                  inputProps={{
                    "aria-label": "Filter",
                    style: {
                      height: "42px",
                      paddingTop: "0",
                      paddingBottom: "0",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: "8px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      },
                    },
                  }}
                >
                  <MenuItem value="">Filtres</MenuItem>
                  <MenuItem value="option1">Option 1</MenuItem>
                  <MenuItem value="option2">Option 2</MenuItem>
                </Select>
              </div>
            </FormControl>

            <Button  style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer component={Paper} className="shadow-md sm:rounded-lg">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={handleSelectAll}
                    checked={selectedPrograms.length === programs.length}
                  />
                </TableCell>
                <TableCell>Titre de l'appel à candidature</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Nb acceptées</TableCell>
                <TableCell>Nb de candidatures</TableCell>
                <TableCell>Nb rejetées</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Date de clôture</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((program) => (
                  <TableRow
                    hover
                    key={program.id}
                    selected={isSelected(program.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(program.id)}
                        onChange={() => handleSelectProgram(program.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal', maxWidth: 200 }}>{program.title}</TableCell>
                    <TableCell>
                      <span>{getStatusIcon(program.status)}</span>{" "}
                      <span
                        className={
                          program.status === "active"
                            ? "text-green-600"
                            : program.status === "pending"
                            ? "text-yellow-600"
                            : program.status === "inactive"
                            ? "text-red-600"
                            : "text-gray-600"
                        }
                      >
                        {program.status}
                      </span>
                    </TableCell>
                    <TableCell>{program.nbAccepted}</TableCell>
                    <TableCell>{program.nbCandidatures}</TableCell>
                    <TableCell>{program.nbRejected}</TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(program.createdAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(program.expiresIn)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/Appels-à-candidatures/${program.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[6px] rounded-full text-xs"
                      >
                                              Voir détails

                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={programs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default ListeDesAppelsACandidatures;
