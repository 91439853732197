import proposImg1 from "../../assets/images/Image1-propos.png";
import proposImg2 from "../../assets/images/Image2-propos.png";

const Visibility = () => {
  return (
    <div className="flex flex-wrap ">
      <div className="w-full lg:w-2/5 px-2 mb-4 lg:mb-0">
        <img className="w-full h-auto mb-2" src={proposImg1} alt="Image 1" />
        <div className="flex space-x-2">
          <div className=" w-1/3 flex flex-col justify-center align-items-center text-white bg-[#0976BC] rounded-xl">
            <span className="text-4xl font-bold"> 500K</span>
            <span className="text-xs">Utilisateurs Actifs</span>
          </div>
          <img className="w-2/3 h-auto" src={proposImg2} alt="Image 3" />
        </div>
      </div>

      <div className="w-full lg:w-3/5 px-2">
        <div className="bg-white p-6">
          <h2 className="text-3xl font-bold mb-4 font-roboto-slab ">
            TRANSFORME :<br />
            C’est quoi ?
          </h2>
          <p className="text-gray-700 text-sm mb-4">
            Le projet COPA TRANSFORME RDC est une initiative du gouvernement
            congolais, en partenariat avec la Banque mondiale, visant à soutenir
            l'entrepreneuriat et la croissance des petites et moyennes
            entreprises (PME) en RDC. Il s'appuie sur la Stratégie nationale de
            développement des PME pour diversifier l'économie du pays.
          </p>
          <p className="text-gray-700 text-sm">
            L'objectif principal du projet est de favoriser la croissance et la
            création d'emplois au sein des PME, en particulier celles dirigées
            par des femmes, dans plusieurs villes ciblées comme Kinshasa,
            Matadi, et Bukavu.
          </p>
          <p className="text-gray-700 text-sm">
            COPA TRANSFORME RDC succède au PADMPME, un projet pilote qui a
            précédé la Stratégie nationale de développement des PME, mise à jour
            en 2016.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Visibility;
