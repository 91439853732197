import React from 'react'
import Banner from './Banner'
import CandidatureOffers from './CandidatureOffers'


const AppelsCandidature = () => {
  return (
    <div className='flex flex-col'>
     <Banner/>
     <CandidatureOffers/>
    </div>
  )
}

export default AppelsCandidature