
export const previewFormJuridique = (formSting) => {
  let title 

  if(formSting){
      const json = JSON.parse(formSting)
      title = json.title 
    }
    return (
      <div className='flex justify-center items-center'>
        <p>{title|| 'sans titre'}</p>
      </div>
    );
  };