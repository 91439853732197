import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSurveyResponseById } from '../../../services/surveyResponse.service';
import { CheckCircleIcon, DocumentTextIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Model, Survey } from 'survey-react-ui';
import { PlainLight } from "survey-core/themes";


const DetailsAppelACandidatureSoumis = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchSurveyResponse = async () => {
      try {
        const responseData = await getSurveyResponseById(id);
        setData(responseData);
        if (data) {
          const parsedJson = JSON.parse(data?.surveyResponse?.program?.form?.fields)
          const survey = new Model(parsedJson)
          setSurveyModel(survey)
          survey.data = data.surveyResponse.responses
          survey.mode = "display"
          survey.applyTheme(PlainLight);
          survey.locale = "fr";
        }
      } catch (error) {
        console.error('Error fetching survey response:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResponse();
  }, [id, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Data not found</div>;
  }

  const renderSurveyResponses = (responses) => {
    return Object.entries(responses).map(([question, answer], index) => (
      <div key={index} className="my-2">
        <span className="font-bold">{question}:</span> {typeof answer === 'object' ? JSON.stringify(answer) : answer}
      </div>
    ));
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails de candidature</h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Status :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Brouillon"
                    ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                    : data?.surveyResponse?.status === "Éligible"
                    ? "text-black bg-green-100 border border-green-900"
                    : data?.surveyResponse?.status === "Rejeté"
                      ? "text-red-800 bg-red-100 border border-red-900"
                      : "text-blue-800 bg-blue-100 border border-blue-900"
                }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 text-lg">Nom et prénom :</span>{" "}
            {data?.userData?.username || "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <hr />
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">
              Description du projet :
            </span>{" "}
            {data?.surveyResponse?.program?.description || "N/A"}
          </div>
          <div className="text-gray-700 mt-4">
            {/* <span className="text-gray-950  text-lg">Vos réponses :</span>
              <div className="mt-2">
                {data?.surveyResponse?.responses
                  ? renderSurveyResponses(data.surveyResponse.responses)
                  : "N/A"}
              </div> */}
          </div>
          <hr />
          {data.file && (
            <div className="mt-6 bg-gray-100 border border-gray-300 rounded-lg p-4 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="/path-to-your-pdf-icon"
                  alt="PDF Icon"
                  className="w-8 h-8 mr-4"
                />
                <div>
                  <p className="text-sm font-semibold">
                    {data.file.name || "File.pdf"}
                  </p>
                  <p className="text-xs text-gray-500">
                    {data.file.size || "File size"} - Completed
                  </p>
                </div>
              </div>
              <button className="text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          {surveyModel && <Survey model={surveyModel} />}

          <div className="mt-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la page d'accueil
            </button>
            {/* <a
                href={data.file?.downloadLink || "#"}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              >
                Télécharger
              </a> */}
          </div>
          {/* <div className="bg-gray-100 rounded-lg flex px-4 py-3 justify-between items-center">
              <div className="flex items-center space-x-2">
                <DocumentTextIcon className="h-9 w-9 text-gray-500" />
                <div className="flex flex-col text-sm">
                  <div className="flex ">
                    <span>Projet-1.pdf</span>
                  </div>
                  <span className="text-gray-500 flex items-center">
                    Completed
                    <CheckCircleIcon className="h-5 w-5 text-green-500 bg-green-100 rounded-full ml-1 " />
                  </span>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <TrashIcon className="h-6 w-6 text-gray-600 cursor-pointer hover:text-red-600" />
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default DetailsAppelACandidatureSoumis;
