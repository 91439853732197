import { CheckIcon } from "@heroicons/react/24/outline";
import proposImg3 from "../../assets/images/Image3-propos.png";
import proposImg4 from "../../assets/images/Image4-propos.png";

const Mission = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-8 mt-5">
      <div className="w-full md:w-1/2 mb-8 md:mb-0">
        <h2 className=" mb-2 font-roboto-slab text-3xl">Notre mission</h2>
        <h3 className="text-xs text-gray-600 mb-6 pr-[30%]">
          Le Projet COPA TRANSFORME RDC vise à fournir un ensemble
          d’interventions qui :
        </h3>
        <ul className="space-y-4 ps-0 pr-3">
          <li className="flex items-center">
            <CheckIcon className="h-6 w-6 text-green-500 mr-2" />
            <span className="text-sm">
              Renforcent le vivier d’une nouvelle génération de MPME et
              d’entrepreneurs, en particulier les femmes.
            </span>
          </li>
          <li className="flex items-center">
            <CheckIcon className="h-6 w-6 text-green-500 mr-2" />
            <span className="text-sm">
              Faciliter le financement pour la croissance des PME, y compris
              celles soutenues par des subventions de la Banque mondiale.
            </span>
          </li>
          <li className="flex items-center">
            <CheckIcon className="h-6 w-6 text-green-500 mr-2" />
            <span className="text-sm">
              Continuent d’appuyer les réformes de l’environnement des affaires
              et les améliorations de l’écosystème entrepreneurial.
            </span>
          </li>
        </ul>
      </div>

      <div className="w-full md:w-1/2 flex space-x-4">
        <img src={proposImg3} alt="Image 1" className="w-1/2 object-cover" />
        <img src={proposImg4} alt="Image 2" className="w-1/2 object-cover" />
      </div>
    </div>
  );
};

export default Mission;
