import React from "react";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="bg-[#F6F6F6] py-8">
      <div className="container mx-auto px-[10%]">
        <div className="mb-4 md:text-left">
          <img src={logo} className="w-[40%] md:w-[20%] md:mx-0" alt="Logo" />
        </div>
        <div className="flex flex-col md:flex-row mt-[15px] gap-4 text-sm">
          <div className="flex-1">
            <h5 className="md:text-left">Notre Plateforme</h5>
            <p className="text-[#808080] md:text-left">
              Lorem ipsum dolor sit amet consectetur. Ac in dictumst odio ut
              lobortis turpis velit. Integer elit mattis sapien duis accumsan
              lacus in.
            </p>
          </div>
          <div className="flex-1">
            <h5 className="md:text-left">À propos</h5>
            <p className="text-[#808080] md:text-left">Comment ça fonctionne</p>
            <p className="text-[#808080] md:text-left">Actualités</p>
            <p className="text-[#808080] md:text-left">Partenaires</p>
          </div>
          <div className="flex-1">
            <h5 className="md:text-left">Plus</h5>
            <p className="text-[#808080] md:text-left">Programmes</p>
            <p className="text-[#808080] md:text-left">
              Processus de Sélection
            </p>
            <p className="text-[#808080] md:text-left">Témoignages</p>
            <p className="text-[#808080] md:text-left">Contact</p>
          </div>
          <div className="flex-1">
            <h5 className="md:text-left">Réseaux Sociaux</h5>
            <p className="text-[#808080] md:text-left">LinkedIn</p>
            <p className="text-[#808080] md:text-left">Instagram</p>
            <p className="text-[#808080] md:text-left">Twitter</p>
            <p className="text-[#808080] md:text-left">Facebook</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
