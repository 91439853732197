import React from 'react'

const Counter = () => {
  return (
    <>
      <h2 className="text-center mt-5 mb-4 text-3xl font-roboto-slab px-[20%]">
        Les entreprises du monde entier font confiance à Transforme
      </h2>
      <div className="flex justify-around px-[8%] ">
        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">10 ans</h3>
          <span className="text-xs">In Business</span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">75,000+</h3>
          <span className="text-xs">Customers</span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">100k+</h3>
          <span className="text-xs">Monthly Blog Readers</span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">1.2m+</h3>
          <span className="text-xs">Social Followers</span>
        </div>
      </div>
    </>
  );
}

export default Counter