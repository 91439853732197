import { ChevronDownIcon, MagnifyingGlassIcon, DocumentPlusIcon } from '@heroicons/react/24/solid';
import { Checkbox, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react'
import { getSurveyResponseById, getSurveyResponses } from '../../services/surveyResponse.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { assignFormJuridique, getAllFormsJur } from '../../services/form-juridique.service';
import { AuthContext } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { previewFormJuridique } from '../../helpers/previewFormJuridique';
import { ModalPreviewFormJuridique } from '../../components/common/Modal';
import { ToastContainer, toast, Bounce } from 'react-toastify';

const ListeFormJuridique = () => {
  const navigate = useNavigate()
      const [filter, setFilter] = useState("");
      const {userId} = useContext(AuthContext)
      const { id } = useParams();

      const fetchSurveyById = async () => {
        try {
          const data = await getSurveyResponseById(id);
          return data;
        } catch (error) {
          console.log("error form juridique candidature par id", error)
        }
      }

      const programQuery = useQuery({
        queryKey: ["survey"],
        // queryKey: ["formJur", searchQuery, page, rowsPerPage],
        queryFn: fetchSurveyById,
      })

      console.log('programQuery', programQuery, "######")
    
      const handleFilter = (event) => {
        setFilter(event.target.value);
      };
      const handleChange = (event) => {
        const parsedFilterObject = JSON.parse(event.target.value);
      };
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [selected, setSelected] = useState([]);
      const [totalRows, setTotalRows] = useState(0);
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };  
      const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = data.map((row) => row.id);
          setSelected(newSelected);
          return;
        }
        setSelected([]);
      };


      const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
    
        setSelected(newSelected);
      };
    
    
      // const handleClick = (event, name) => {
      //   const selectedIndex = selected.indexOf(name);
      //   let newSelected = [];
    
      //   if (selectedIndex === -1) {
      //     newSelected = newSelected.concat(selected, name);
      //   } else if (selectedIndex === 0) {
      //     newSelected = newSelected.concat(selected.slice(1));
      //   } else if (selectedIndex === selected.length - 1) {
      //     newSelected = newSelected.concat(selected.slice(0, -1));
      //   } else if (selectedIndex > 0) {
      //     newSelected = newSelected.concat(
      //       selected.slice(0, selectedIndex),
      //       selected.slice(selectedIndex + 1)
      //     );
      //   }
    
      //   setSelected(newSelected);
      // };
    
      const fetchJurForms = async () => {
        try {
          const data = await getAllFormsJur({
            // page: page + 1,
            // limit: rowsPerPage,
            // searchTerm: searchQuery,
            coachId: userId,
          });
          const totalDocs = data?.totalDocs;
          setTotalRows(totalDocs);
          console.log("my total dzdzedzed", totalDocs);
          return data.data;
        } catch (error) {
          console.error("Error fetching survey responses:", error);
        }
      };
      const [searchQuery, setSearchQuery] = useState("");
    
      const isSelected = (name) => selected.indexOf(name) !== -1;
      const { data, error, isLoading } = useQuery({
        queryKey: ["formJur"],
        // queryKey: ["formJur", searchQuery, page, rowsPerPage],
        queryFn: fetchJurForms,
      });

    
      const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, 24 - page * rowsPerPage);
    
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };

      const assignerForm = async (idForm) => {
        try {
          const response = await assignFormJuridique(idForm, id)
          if(response.message){
            toast.success('Formulaire assigné avec succès', {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
              onClose: () => navigate(-1)
            });
          }
        } catch (error) {
          console.log("error in assigning formJur to candidature")
        }
      }
    
      return (
        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
          <h1 className="text-3xl font-bold mb-0">Liste des formulaires juridiques</h1>
          <p className="text-gray-500 text-sm  mb-2">
            Assigner ou créer des formulaires juridique aux condidatures.
          </p>
          <div className="flex justify-between items-center">
            <div>
              <p className=''>{programQuery?.data?.surveyResponse?.program?.title}</p>
              <p>Candidature ID : {programQuery?.data?.surveyResponse?.id}</p>
            </div>
            <Link className='flex gap-2 bg-[#0976BC] p-2 rounded-[8px] text-white no-underline  ' to="creer-form-juridique">
                <DocumentPlusIcon className='w-6 h-6'/>
                Créer un nouveau formulaire 
            </Link>
          </div>
          <div className="border rounded-lg p-3 bg-white">
            <div className="flex items-center justify-between mb-4">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  className="py-[12px] ps-12 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none min-w-80"
                  placeholder="Rechercher par date, Réf ID, Document,..."
                  required
                />
              </div>
    
              <div className="flex items-center space-x-4">
                <button
                  class="bg-white border  text-secondary-foreground hover:bg-secondary/80 px-4 py-2
               rounded-[4px] flex gap-2 items-center"
                >
                  <i class="bi bi-file-earmark-arrow-down"></i>Export
                </button>
    
                <FormControl variant="outlined" sx={{ width: 150 }}>
                  <Select
                    value={filter}
                    onChange={handleFilter}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                    )}
                    renderValue={(selected) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <i className="bi bi-filter  text-xl mx-1"></i>
                        <span style={{ flex: 1, textAlign: "center" }}>
                          {selected || <>Filter</>}
                        </span>
                      </div>
                    )}
                    sx={{
                      backgroundColor: "white",
                      color: "#333",
                      borderRadius: "4px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #dee2e6",
                      },
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "30px",
                        paddingRight: "2.5rem",
                      },
                      "& .MuiSelect-icon": {
                        display: "none",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <>Filtre</>
                    </MenuItem>
                    <MenuItem value="Status">Status</MenuItem>
                    <MenuItem value="Appel à candidature -2">Date</MenuItem>
                    <MenuItem value="Appel à candidature -3">Filter -3</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
    
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < data?.length
                          }
                          checked={
                            data?.length > 0 && selected?.length === data?.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell align="center">ID Formulaire</TableCell>
                      <TableCell align="center">Titre formulaire</TableCell>
                      <TableCell align="center">Date de création</TableCell>
                      <TableCell align="center">Visualiser formulaire</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      data?.map((row) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${row.id}`;
    
                        return (
                          <TableRow
                            key={row.id}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {row.id}
                            </TableCell>
                            <TableCell align="center">
                              {previewFormJuridique(row.fildes)}
                            </TableCell>
                            <TableCell align="center">
                              {format(
                                new Date(row.createdAt),
                                "dd/MM/yyyy HH:mm:ss"
                              )}
                            </TableCell>
                         
                            <TableCell align="center">
                              <ModalPreviewFormJuridique formString={row.fildes}/>
                            </TableCell>
                            <TableCell align="center">
                              <button onClick={() => assignerForm(row.id)} className='bg-[#0976BC] text-white no-underline rounded-full px-3 py-[5px] hover:bg-[#065A94] transition-colors duration-300 text-xs'>
                                Assigner
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
    
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {totalRows && (
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  count={totalRows} // Updated to use totalDocs from API response
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )} */}
            </Paper>
          </div>
          <ToastContainer/>
        </div>
      );
}

export default ListeFormJuridique