import React from 'react'
import RegisterForm from '../../../components/forms/RegisterForm'

const Register = () => {
  return (
    <div>
        {/* <h1>Register page</h1> */}
        <RegisterForm/>
    </div>
  )
}

export default Register