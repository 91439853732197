import axios from "axios";

export const loginService = async (values) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/authentication/login`,
      values
    );
    return response;
  } catch (error) {
    console.error("Error fetching active programs:", error);
    throw error;
  }
};

export const forgetPasswordService = async (values) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/authentication/forgot-password`,
      values
    );
    return response;
  } catch (error) {
    console.error("Error fogot password :", error);
    throw error;
  }
};
