import React from 'react';
import { useNavigate } from 'react-router-dom';
import createForm from '../../../../assets/createNVForm.png';
import assignForm from '../../../../assets/assigneNVFormIcon.png';
import { Button } from '@mui/material';
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

const StepTwo = ({ handlePrevious }) => {
    const navigate = useNavigate();

    const handleCreateNewFormClick = () => {
        navigate('create-form');
    };

    const assignFormClick = () => {
        navigate('assign-form');
    }

    return (
      <div className="p-8 bg-white shadow-md rounded-lg">
        <div className="flex justify-center gap-12 mb-8">
          <div
            className="bg-gray-50 hover:bg-gray-100 p-8 rounded-lg border border-gray-500 text-center hover:shadow-sm cursor-pointer transition-all"
            onClick={handleCreateNewFormClick}
          >
            <img
              src={createForm}
              alt="Créer nouveau formulaire"
              className="w-22 h-28 mx-auto mb-4"
            />
            <button className="bg-blue-500 text-white px-6 py-3 rounded-lg font-medium">
              Créer nouveau formulaire
            </button>
          </div>
          <div
            className="bg-gray-50 hover:bg-gray-100 p-8 rounded-lg border border-gray-500 text-center hover:shadow-sm cursor-pointer transition-all"
            onClick={assignFormClick}
          >
            <img
              src={assignForm}
              alt="Assigner Formulaire Existant"
              className="w-22 h-28 mx-auto mb-4"
            />
            <button className="bg-blue-500 text-white px-6 py-3 rounded-lg font-medium">
              Assigner formulaire existant
            </button>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          {/* <button
            onClick={handlePrevious}
            className="bg-gray-300 text-black px-6 py-3 rounded-lg"
          >
            Précédent
          </button> */}

          <div className="d-flex justify-content-end mt-4">
            <Button
              onClick={handlePrevious}
              variant="contained"
              color="primary"
              startIcon={<ArrowLeftIcon className="w-5 h-5" />}
              className=" bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              style={{ textTransform: "none" }}
            >
              Précédent
            </Button>
          
          
          </div>
        </div>
      </div>
    );
};

export default StepTwo;
