import { Formik, Form } from "formik";
import React from "react";
import InputField from "../common/InputField";
import Button from "../common/Button";
import * as Yup from "yup";
const ContactForm = () => {
  const initialValues = {
    name: "",
    email: "",
    adresse: "",
    sujet: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Nom et prénom requis"),
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("Adresse e-mail requise"),
    adresse: Yup.string().required("Adresse requise"),
    sujet: Yup.string().required("Sujet requis"),
    message: Yup.string().required("Message requis"),
  });

  const onSubmit = (values) => {
    console.log("Form Values:", values);
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        <div className="w-[90%] p-[35px] md:w-[70%] xl:w-[60%] mx-auto xl:p-[55px] rounded-2xl bg-white drop-shadow-lg">
          <Form className="flex flex-col gap-3">
            <div className="lg:flex gap-2 grow">
              <InputField
                type="text"
                name="name"
                label="Nom et prénom *"
                placeholder="Votre nom et prénom"
              />
              <InputField
                type="email"
                name="email"
                label="Adresse e-mail *"
                placeholder="exemple@votremail.com"
              />
            </div>
            <div className="lg:flex gap-2 grow">
              <InputField
                type="text"
                name="adresse"
                label="Adresse *"
                placeholder="Votre adresse"
              />
              <InputField
                type="text"
                name="sujet"
                label="Sujet *"
                placeholder="Informez-nous sur le sujet"
              />
            </div>
            <div>
              <InputField
                as="textarea"
                name="message"
                label="Message *"
                placeholder="Comment pouvons-nous vous aider ?"
                rows={5}
              />
            </div>
            <div className="mt-4 mb-2 flex justify-center">
              <Button className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300">
                Envoyer le message
              </Button>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
};

export default ContactForm;
