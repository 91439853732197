import axios from "axios";

export const createForm = async (formData) => {
  try {
    const response = await axios.post(
      `
            ${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/forms`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error creating form", error);
    throw error;
  }
};

export const getAllForms = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/forms?page=1&per_page=10`
    );
    console.log(response);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching all forms:", error);
    throw error;
  }
};


export const linkFormToProgram = async ({ formId, programId }) => {
  try {
      const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/forms/link-from-to-program/${formId}/${programId}`,
         
      );
      return response.data;
  } catch (error) {
      console.error("error linking form to program", error);
      throw error;
  }
};