import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import InputField from "../common/InputField";
import axios from "axios";
import mailIcon from '../../assets/Mail.svg'

const RegisterForm = () => {
  const [confModal, setConfModal] = useState(false)
  const [errModal, setErrModal] = useState(false)

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Nom est requis'),
    last_name: Yup.string().required('Prénom est requis'),
    email: Yup.string()
      .email('Adresse e-mail invalide')
      .required('Adresse e-mail est requis'),
  });
  

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/authentication/create`, values)
      if (response.status === 201) {
        setConfModal(true)
      }
    } catch (error) {
      console.log(error)
      setErrModal(true)
    }
  };


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center mb-4">
        <img
          src="/static/media/logo.fe0c676b08bfa09e6f3f.png"
          alt="Logo"
          className="w-50"
        />
      </div>
      {confModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60 ">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">
            <img src={mailIcon} alt="" width={100} height={100} />
            <h3>Votre compte a été créé avec succès !</h3>
            <p className="text-center text-[#151269]">
              Veuillez vérifier votre e-mail pour finaliser la création de votre mot de passe.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={() => setConfModal(false)}
            >
              <Link to="/auth/login" className="text-white no-underline">Se connecter</Link>
            </button>
          </div>
        </div>
      )}

      {errModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60 ">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">

            <h3>L'utilisateur existe déja</h3>
            <p className="text-center text-[#151269]">
              Veuillez essayer avec un aute nom utilisateur ou email.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={() => setErrModal(false)}
            >
              <Link to="/auth/register" className="text-white no-underline">Ressayer</Link>
            </button>
          </div>
        </div>
      )}


      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl">
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-4xl font-bold text-[#0976BC]">Bienvenue</h1>
          <p className="text-center text-[#3D3D3D] text-sm mt-2">
            Entrer vos identifiants pour créer votre compte
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          <Form className="flex flex-col gap-2">
            <InputField type='text' placeholder='Entrer votre nom' name="first_name" label="Nom *" />
            <InputField type='text' placeholder='Entrer votre prénom' name="last_name" label="Prénom *" />
            <InputField type="email" name="email" label="Adresse e-mail *" placeholder="Entrer votre adresse e-mail" />

            <div className="mb-6 ">
              <p className="text-gray-600 text-xs">
                En créant un compte, vous acceptez les{" "}
                <a
                  href="/terms"
                  className="text-[#0976BC] hover:text-[#065A94] font-semibold"
                >
                  Conditions d'utilisation
                </a>{" "}
                et la{" "}
                <a
                  href="/privacy"
                  className="text-[#0976BC] hover:text-[#065A94] font-semibold"
                >
                  Politique de confidentialité.
                </a>
              </p>
            </div>
            <div>
              <button
                className="w-full bg-[#0976BC] hover:bg-[#065A94] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                type="submit"
              >
                S’inscrire
              </button>
            </div>
          </Form>
        </Formik>
        <div className="mt-6 text-center">
          <p className="text-gray-600 text-sm">
            Déjà un compte?{" "}
            <Link to="/auth/login"

              className="text-[#0976BC] hover:text-[#065A94] font-semibold"
            >
              Connectez-vous
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
