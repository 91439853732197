import React, { useEffect, useState, useContext } from "react";
import { BsCalendar, BsCalendar2, BsFileEarmarkArrowDown, BsFilter, BsSearch } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllForms } from "../../../../../services/form.service";
import { StepContext } from "../../../../../contexts/StepContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/24/outline"; // Import Heroicons
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { CalendarIcon } from "@heroicons/react/24/outline";

const Formulaires = () => {
  const [forms, setForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { setCurrentStep, setSelectedFormId } = useContext(StepContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllFormsData = async () => {
      try {
        const data = await getAllForms();
        setForms(data);
      } catch (error) {
        console.error("Error fetching forms:", error);
      }
    };
    getAllFormsData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCheckboxChange = (formId) => {
    setSelectedForms((prevSelected) =>
      prevSelected.includes(formId)
        ? prevSelected.filter((id) => id !== formId)
        : [...prevSelected, formId]
    );
  };

  const handleAssign = (formId) => {
    setSelectedFormId(formId);
    setCurrentStep(3);
    navigate("../");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="border rounded-lg p-3 bg-white mt-0">
      <h2 className="text-2xl font-bold text-gray-800 flex justify-center m-2">
        Assigner un formulaire
      </h2>
      <div className="bg-white border rounded-lg p-3">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, Réf ID, Document,..."
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md">
              <BsCalendar2 className="mr-2" />
              Date
            </Button>
            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md">
              <BsFilter className="mr-2" />
              Filtres
            </Button>

            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Titre formulaire</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Dernière modification</TableCell>
                <TableCell>Voir formulaire</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((form) => (
                  <TableRow
                    key={form.id}
                    className={`hover:bg-gray-50 ${
                      selectedForms.includes(form.id) ? "bg-blue-50" : ""
                    }`}
                  >
                    <TableCell>{form.title}</TableCell>
                    <TableCell>
                         <div className="flex items-center gap-1">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                        {formatDate(form.createdAt)}
                        </div>
                        </TableCell>
                    <TableCell>
                        <div className="flex items-center gap-1">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                        {formatDate(form.updatedAt)}
                        </div>
                        </TableCell>
                    <TableCell>
                      <IconButton
                        className="hover:bg-[#bfe0f5]"
                        onClick={() => handleAssign(form.id)}
                      >
                        <EyeIcon className="h-5 w-5 text-gray-500 hover:text-[#0976BC]" />
                      </IconButton>
                    </TableCell>

                    <TableCell>
                      <button
                        onClick={() => handleAssign(form.id)}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[6px] rounded-full text-xs"
                      >
                        Assigner
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={forms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <div className="flex justify-end space-x-4 mt-4">
        <button
          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[25px] py-[8px] rounded-full text-base"
          onClick={() => navigate(-1)}
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

export default Formulaires;
