import React from 'react'
import LoginForm from '../../../components/forms/LoginForm'

const Login = () => {
  return (
    <div>
       <LoginForm/>
    </div>
  )
}

export default Login