import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BsCalendar, BsFileEarmarkArrowDown, BsFilter } from "react-icons/bs";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import SuccessModal from "./SuccessModal";
import { assignMultipleSurveys, getUsersByGroupId } from "../../../services/user.service";

const AssignCoach = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isAssigning, setIsAssigning] = useState(false); 
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const groupId = "7fb82c3b-2096-4727-836a-0cfd0b2c1719";

  const getQueryParams = (query) => {
    return new URLSearchParams(query).getAll("ids");
  };

  const selectedIds = getQueryParams(location.search);

  const fetchCoaches = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUsersByGroupId(groupId, page + 1, rowsPerPage);
      setCoaches(response);
      setTotalCount(response.length);
    } catch (error) {
      console.error("Error fetching coaches:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, groupId]);

  useEffect(() => {
    fetchCoaches();
  }, [fetchCoaches]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAssignCoach = async (coach) => {
    if (!coach || selectedIds.length === 0) return;

    setIsAssigning(true);

    try {
      await assignMultipleSurveys(coach.id, selectedIds);
      setSelectedCoach(coach);
      setSuccessModalOpen(true);
    } catch (error) {
      console.error("Error assigning surveys:", error);
      alert("Failed to assign surveys. Please try again.");
    } finally {
      setIsAssigning(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    setSelectedCoach(null); 
    navigate("../liste-condidatures", { replace: true })
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Assigner un coach</h1>
      <p className="text-gray-500 text-sm mb-2">
        Voici la liste des coachs disponibles pour assignation
      </p>

      <div className="border rounded-lg p-3 mt-4 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none focus:ring-0 focus:border-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par date, Réf ID, Document,..."
              required
            />
          </div>
          <div className="flex items-center space-x-4">
            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsCalendar className="mr-2" />
              Date
            </Button>
            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsFilter className="mr-2" />
              Filtres
            </Button>
            <Button className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          {loading ? (
            <div className="flex justify-center my-4">
              <CircularProgress />
            </div>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom du coach</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coaches
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((coach) => (
                    <TableRow key={coach.id}>
                      <TableCell>
                        {coach.firstName} {coach.lastName}
                      </TableCell>
                      <TableCell>{coach.email}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 capitalize text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                          onClick={() => handleAssignCoach(coach)}
                          disabled={isAssigning}
                        >
                          {isAssigning ? "Assigning..." : "Assigner"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Candidatures sélectionnées</h2>
        <ul className="list-disc pl-5 mt-2">
          {selectedIds.map((id) => (
            <li key={id}>Candidature ID : {id}</li>
          ))}
        </ul>
      </div>

      {successModalOpen && (
        <SuccessModal
          open={successModalOpen}
          onClose={handleCloseSuccessModal}
          coach={selectedCoach} 
          message="Surveys successfully assigned to coach."
        />
      )}
    </div>
  );
};

export default AssignCoach;
