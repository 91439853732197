import React from 'react'
import ForgetPasswordForm from '../../../components/forms/ForgetPasswordForm'

const ForgetPassword = () => {
  return (
    <div>
        <ForgetPasswordForm />
    </div>
  )
}

export default ForgetPassword