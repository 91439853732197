import React from "react";

const Button = ({ children, ...props }) => {
  return (
    <button
      type="submit"
      className="text-white p-2 rounded-[37.5px] bg-[#0976BC] w-full mt-1 hover:bg-blue-400"
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
