import axios from "axios";

export const sendConvention = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/send-convention/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error sending convention", error);
    throw error;
  }
};

export const remindSignConvention = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/remind-sign-convention/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error reminding to sign convention", error);
    throw error;
  }
};

export const submitConventionSignature = async (surveyResponseId, files) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/sign-convention/${surveyResponseId}`,
      {
        files,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to submit convention signature:", error);
    return { success: false };
  }
};
