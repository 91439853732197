import axios from "axios";



/**
 * Fetches all available programs from the server.
 * @returns {Promise<Array>} - An array of programs from the server's response.
 * @throws Will throw an error if the request to fetch programs fails.
 */
export const getAllPrograms = async () => {
  console.log( `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs`)
  console.log(`${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}`)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs`
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching all programs:", error); 
    throw new Error("Failed to fetch programs. Please try again later.");
  }
};

export const getAllProgramsFilters = async () => {

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs/list-all?page=1&per_page=100`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching all programs:", error); 
    throw new Error("Failed to fetch programs. Please try again later.");
  }
};

/**
 * Fetches a specific program by its ID from the server.
 * @param {string} id - The unique identifier of the program.
 * @returns {Promise<Object>} - The program data from the server's response.
 * @throws Will throw an error if the request to fetch the program fails.
 */
export const getProgramById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching program by ID (${id}):`, error); // Improved error message with ID
    throw new Error("Failed to fetch the program. Please try again later.");
  }
};

export const getProgramToFill = async (programId, keyclockUserId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs/get-program-to-fill/${programId}/${keyclockUserId}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching program to fill (${programId}):`, error); // Improved error message with ID
    throw new Error("Failed to fetch the program to fill. Please try again later.");
  }
};

export const createProgram = async (formData) => {
  try {
      const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/programs`,
          formData,  
          {
              headers: {
                  "Content-Type": "multipart/form-data",  
              },
          }
      );
      return response.data;
  } catch (error) {
      console.error("error creating program", error);
      throw error;
  }
};