import React, { useEffect, useState } from "react";
import ProgramCard from "../../components/cards/ProgramCard";
import { getAllPrograms } from "../../services/program.service";

const CandidatureOffers = () => {
  const [latestPrograms, setLatestPrograms] = useState([]);
  console.log(latestPrograms, "haahahah");

  useEffect(() => {
    const getLatestPrograms = async () => {
      try {
        const data = await getAllPrograms();
        const sortedPrograms = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        const latestThreePrograms = sortedPrograms.slice(0, 3);
        setLatestPrograms(latestThreePrograms);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    getLatestPrograms();
  }, []);

  return (
    <div className="container my-10  items-center bg-center justify-center flex flex-col gap-3">
      {latestPrograms.map((program) => (
        <ProgramCard
          id={program.id}
          category={program.category}
          title={program.title}
          description={program.description}
          key={program.id}
          ddl={program?.expiresIn}
          img={program?.image?.url}
        />
      ))}
    </div>
  );
};

export default CandidatureOffers;
