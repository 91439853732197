import React from "react";
import BannerImage from "../../assets/BannerAppels.svg";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Banner = () => {
  return (
    <div className="flex justify-center items-center md:w-[80%] w-full mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative pt-3 mb-4">
      <img
        src={BannerImage}
        className="h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute rounded-none sm:rounded-[16px]"
        alt="Banner"
      />
      <div className="relative text-white flex flex-col justify-center items-center gap-2">
        <h1 className="text-center font-roboto-slab text-5xl">
          Appel à candidature
        </h1>
        <p className="text-center w-[60%]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>

      <div className="absolute bottom-0 translate-y-1/2 w-full flex justify-center">
        <div className="relative md:w-[60%] w-[90%]">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-6 w-6 text-[#0976BC]" />
          <input
            type="text"
            placeholder="Recherche..."
            className="w-full pl-10 pr-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
