import React, { useContext, useState } from 'react';
import { StepContext } from '../../../../contexts/StepContext';
import { createProgram } from '../../../../services/program.service';
import { createForm, linkFormToProgram } from '../../../../services/form.service';
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const StepThree = ({ handlePrevious }) => {
    const { formData, images, selectedFormId } = useContext(StepContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('exigence', formData.exigence);
            formDataToSend.append('expiresIn', new Date(formData.submissionDate).toISOString());
            formDataToSend.append('status', formData.status);
            formDataToSend.append('category', formData.category);

            if (images.length === 0) {
                alert("Please upload at least one image.");
                return;
            }

            images.forEach((image) => {
                if (image instanceof File) {
                    formDataToSend.append('image', image);
                } else {
                    console.error("Invalid image file.");
                }
            });

            const programResponse = await createProgram(formDataToSend);
            const programId = programResponse.id;
            console.log(programId, "programId");

            let formId;
            const formResponse = await createForm({ fields: formData.surveyData });
            if (formResponse.id) {
                formId = formResponse.id;
            } else {
                formId = selectedFormId;
            }

            console.log(formId, "formId");

            await linkFormToProgram({ formId, programId });

            toast.success('Le programme et le formulaire sont liés avec succès !', {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
            });

            setTimeout(() => {
                navigate("/unite_de_gestion-dashboard/Appels-à-candidatures");
            }, 2000); 

        } catch (error) {
            console.error('Error during submission', error);
            if (error.response && error.response.data) {
                alert(`Error: ${error.response.data.message}`);
            } else {
                alert('There was an error during submission. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
      <div className="p-8 bg-white shadow-md rounded-lg mt-2">
        <h2 className="text-2xl font-semibold">Validation</h2>
        <div className="mt-4">
          <div className="flex items-center bg-slate-100 p-3">
            <span className="w-52">
              <strong>Titre :</strong>
            </span>
            {formData.title}
          </div>

          <div className="flex items-center p-3">
            <span className="w-52">
              <strong>Date limite de soumission :</strong>
            </span>
            {formData.submissionDate}
          </div>

          <div className="flex items-center bg-slate-100 p-3">
            <span className="w-52">
              <strong>Description :</strong>
            </span>
            {formData.description}
          </div>

          <div className="flex items-center p-3">
            <span className="w-52">
              <strong>Status :</strong>
            </span>
            {formData.status}
          </div>

          <div className="flex items-center mb-3 bg-slate-100 p-3">
            <span className="w-52">
              <strong>Catégorie :</strong>
            </span>
            {formData.category}
          </div>

          {images && images.length > 0 && (
            <div className='flex p-3 items-start'>
              <strong>Image(s):</strong>
              {images.map(
                (image, index) =>
                  image instanceof File && (
                    <div key={index} className='ml-3'>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Uploaded ${index + 1}`}
                        style={{ maxWidth: "200px", marginTop: "10px" }}
                      />
                    </div>
                  )
              )}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end items-center  gap-4">
          {/* <button
            onClick={handlePrevious}
            className="bg-gray-300 text-black px-4 py-2 rounded-md"
          >
            Précédent
          </button> */}

          <div className="d-flex  ">
            <Button
              onClick={handlePrevious}
              variant="contained"
              color="primary"
              startIcon={<ArrowLeftIcon className="w-5 h-5" />}
              className=" bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
              style={{ textTransform: "none" }}
            >
              Précédent
            </Button>
          </div>

          <button
            onClick={handleSubmit}
            className=" bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-5 py-2 h-[45px]"
            disabled={isSubmitting}
          >
            {isSubmitting ? <div className="spinner"></div> : "Valider"}
          </button>
        </div>

        <ToastContainer />
      </div>
    );
};

export default StepThree;
