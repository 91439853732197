import React, { useState, useMemo, useCallback, startTransition } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Avatar from "react-avatar";
const UserMenu = ({ userName, img }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { logOut } = useAuth();
  const handleSignOut = () => {
    logOut();
    navigate("");
  };

  // Memoized styles
  const buttonStyles = useMemo(
    () => ({
      border: "1px solid #ccc",
      borderRadius: "20px",
      color: "#666",
      fontWeight: "normal",
      textTransform: "none",
      padding: "6px 15px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }),
    []
  );

  const menuItemStyles = useMemo(
    () => ({
      width: "100%",
      fontWeight: "normal",
      paddingLeft: "30px",
    }),
    []
  );

  const handleRetourSite = useCallback(() => {
    startTransition(() => {
      navigate("/");
    });
  }, [navigate]);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          border: "1px solid #ccc",
          borderRadius: "25px",
          color: "#666",
          fontWeight: "normal",
          textTransform: "none",
          padding: "3px 15px 3px 8px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          backgroundColor: "rgba(25, 118, 210, 0.04)",
          fontWeight: "500",
          textTransform: "capitalize",
        }}
      >
        <Avatar name={userName} round={true} size="34" />
        {/* <img alt="" src={img} className="p-[1px] object-contain" width={34} /> */}
        {userName}
        <ChevronDownIcon style={{ width: "20px", height: "20px" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            minWidth: "inherit",
            width: anchorEl?.clientWidth,
            borderRadius: "20px",
            marginTop: "5px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleRetourSite();
          }}
          sx={{
            width: "100%",
            fontWeight: "400",
            padding: "10px 30px",
            fontSize: "14px",
            borderBottom: "1px solid #f2f2f2",
          }}
        >
          Retour au site
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            handleSignOut();
          }}
          sx={{
            width: "100%",
            fontWeight: "normal",
            padding: "10px 30px",
            fontSize: "14px",
          }}
        >
          Déconnexion
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
