import Banner from "./Banner";
import Counter from "./Counter";
import Visibility from "./Visibility";
import Mission from "./Mission";

import Value from "./Value";

import Team from "./Team";

const About = () => {
  return (
    <>
      <Banner />
      <div className="container my-5">
        <div className="px-[10%] ">
          <Visibility />
          <Counter />
          <Mission />
          <Value />     
        </div>
      </div>
      <Team />
    </>
  );
};

export default About;
