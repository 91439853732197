import React from "react";
import { Field, ErrorMessage } from "formik";

const InputField = (props) => {
  const labelExixts = props.label ? "grow" : "grow-0";

  return (
    <div className={`flex flex-col gap-2 ${labelExixts}`}>
      <label htmlFor={props.name} className="font-semibold">
        {props.label}
      </label>
      <Field
        className="rounded-[10px]  border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-24"
        {...props}
      />
      <ErrorMessage {...props}>
        {(msg) => <div className="text-red-500 mb-1">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default InputField;
