import React, { useContext } from "react";
import Sidebar from "./Sidebar";
import { ActiveMenuContext } from "../../contexts/MenuContext";
import Header from "./Header";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Layout = () => {
  const { activeMenu } = useContext(ActiveMenuContext);
  const { userRole } = useAuth();
  console.log(userRole, "******role");
  const isAuthenticated = localStorage.getItem("access-token") !== null;

  return (
    <div className="">
      <div className="flex relative dark:bg-main-dark-bg">
        <div
          className={`fixed sidebar bg-white transition-all duration-300 ${
            activeMenu ? "w-72" : "w-0"
          }`}
        >
          <Sidebar />
        </div>
        <div
          className={
            activeMenu
              ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full transition-all duration-300"
              : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2 transition-all duration-300"
          }
        >
          <div className="sticky top-0 z-50 w-full bg-white">
            <Header />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
