import React, { createContext, useState } from "react";

export const StepContext = createContext();

export const StepProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [images, setImages] = useState([]);
    const [selectedFormId, setSelectedFormId] = useState(null);  

    const [formData, setFormData] = useState({
        title: '',
        submissionDate: '',
        description: '',
        exigence:'',
        status: 'Publié',
        category: 'technology',
        surveyData: {},
    });

    console.log(formData?.surveyData, "here daata")

    return (
        <StepContext.Provider
            value={{
                currentStep,
                setCurrentStep,
                formData,
                setFormData,
                images,
                setImages,
                selectedFormId,  
                setSelectedFormId,  
            }}
        >
            {children}
        </StepContext.Provider>
    );
};
