import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";


const GetFormJuridique = () => {
    const { surveyResponseId } = useParams();
    const [formData, setFormData] = useState(null);
    const [surveyModel, setSurveyModel] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchFormJuridique = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/get-one-formulaire-juridique/${surveyResponseId}`
                );

                const form = response.data;
                setFormData(form);

                if (form?.fildes) {
                    const parsedSurvey = JSON.parse(form.fildes);
                    const survey = new Model(parsedSurvey);
                    survey.applyTheme(PlainLight);
                    survey.locale = "fr";

                    survey.onComplete.add(async (sender) => {
                        const surveyData = sender.data;
                        try {
                            const uploadUrl = `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/files-management/upload-juridique/${surveyResponseId}`;
                            await axios.post(uploadUrl, surveyData);

                            toast.success("Vos documents juridiques ont été envoyées avec succès!", {
                                position: "bottom-right",
                                autoClose: 2000,
                                theme: "colored",
                                transition: Bounce,
                            });

                            setTimeout(() => {
                                navigate("/laureat-dashboard");
                            }, 2000);
                        } catch (error) {
                            console.error("Error uploading survey data", error);
                            alert("Error uploading survey data. Please try again.");
                        }
                    });

                    setSurveyModel(survey);
                } else {
                    setError("No survey data found");
                }
            } catch (error) {
                setError("Error fetching form data");
                console.error(error);
            }
        };

        if (surveyResponseId) {
            fetchFormJuridique();
        }
    }, [surveyResponseId]);

    return (
        <div>
            {error && <p>{error}</p>}
            {formData ? (
                <div>
                    {surveyModel ? (
                        <Survey model={surveyModel} />
                    ) : (
                        <p>Loading survey...</p>
                    )}
                </div>
            ) : (
                <p>Loading form data...</p>
            )}
            <ToastContainer />

        </div>
    );
};

export default GetFormJuridique;

