import React from 'react';
import { useNavigate } from 'react-router-dom';
import effectue from '../../../assets/effectue.png';

const LaureatGetSurveyForm = () => {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate("/laureat-dashboard");
        localStorage.removeItem("programId");
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg p-10 text-center h-[100vh] flex flex-col justify-center items-center w-[90%] ">
                <div className="flex justify-center mb-5">
                    <img src={effectue} className="w-[120px] h-[120px] " alt="Submission Effectuee" />
                </div>
                <h2 className="text-2xl font-semibold mb-2">Soumission Déjà Effectuée</h2>
                <p className="text-gray-600 mb-6">
                    Désolé, vous avez déjà soumis votre candidature pour cet appel à candidature. <br />
                    Vous ne pouvez pas soumettre deux fois.
                </p>
                <button
                    className="bg-[#0976BC] text-white rounded-full py-2 px-6 font-semibold"
                    onClick={handleGoToDashboard}
                >
                    Retour Dashboard
                </button>
            </div>
        </div>
    );
};

export default LaureatGetSurveyForm;
