import React, { useEffect, useState } from 'react'
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { PlainLight } from "survey-core/themes";
import { Link } from 'react-router-dom';


export const ModalPreviewFormJuridique = ({formString}) => {
  const [surveyModel, setSurveyModel] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(()=>{
 if(formString){
        const survey = new Model(formString)
        survey.mode = "display"
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        setSurveyModel(survey)
    }
  },[])
   
  return (
    <div className='flex justify-center items-center'>
      <button onClick={()=> setOpen(true)} className='underline text-[#0976BC] flex justify-center items-start '>Voir formulaire</button>
      {open && surveyModel && (
        <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60">
          <div className="p-[3%] bg-white rounded-md flex flex-col gap-3 justify-center items-center w-[55vw]">
            <div className='w-full overflow-y-auto'>
              <Survey model={surveyModel} />
            </div>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={() => setOpen(false)}
            >
              <Link to="" className="text-white no-underline">Fermer</Link>
            </button>
          </div>
        </div>
      )}
      </div>
  )
}
