import axios from 'axios';

export const getSurveyResponses = async (queryParams, coachId) => {
  const {
    page,
    limit,
    status,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    programId
  } = queryParams;

  const params = new URLSearchParams();

  if (page) params.append('page', page);
  if (limit) params.append('limit', limit);
  if (status) params.append('status', status);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response/coach/${coachId}`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses:', error);
    throw new Error('Failed to fetch survey responses. Please try again later.');
  }
};
export const getSurveyResponsesForEligibleCandidate = async (queryParams) => {
  const {
    page,
    limit,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    programId
  } = queryParams;

  const params = new URLSearchParams({
    status: 'Éligible',
  });

  if (page) params.append('page', page);
  if (limit) params.append('limit', limit);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses for eligible candidate:', error);
    throw new Error('Failed to fetch survey responses for eligible candidate. Please try again later.');
  }
};



export const sendSurveyResponse = async (token, responseData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response`,
      responseData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending survey response:', error);
    throw new Error('Failed to send the survey response. Please try again later.');
  }
};



export const getSurveyResponsesByUserId = async (userId, queryParams) => {
  const {
    page = 1,
    limit = 10,
    status,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    initialEvaluationNote,
  } = queryParams;

  const params = new URLSearchParams({
    page,
    limit,
  });

  if (status) params.append('status', status);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (initialEvaluationNote) params.append('initialEvaluationNote', initialEvaluationNote);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response/by-user/${userId}`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses by user ID:', error);
    throw new Error('Failed to fetch survey responses by user ID. Please try again later.');
  }
};


export const getSurveyResponseById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response/${id}`,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey response ID:', error);
    throw new Error('Failed to fetch survey response ID. Please try again later.');
  }
};

export const updateSurveyResponseById = async (id, updatedObject) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response/${id}`,
      updatedObject,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating the survey response status:', error);
    throw new Error('Failed to update survey response status. Please try again later.');
  }



  
};


export const getAllSurveyResponses = async (queryParams) => {
  const {
    page,
    limit,
    status,
    searchTerm,
    coachNote,
    startCreatedAt,
    endCreatedAt,
    programId
  } = queryParams;

  const params = new URLSearchParams();

  if (page) params.append('page', page);
  if (limit) params.append('limit', limit);
  if (status) params.append('status', status);
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (coachNote) params.append('coachNote', coachNote);
  if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
  if (programId) params.append('programId', programId);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_NEST_BASE_URL}/survey-response/`,
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey responses:', error);
    throw new Error('Failed to fetch survey responses. Please try again later.');
  }
};